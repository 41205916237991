import React, { useEffect, useState } from "react";
import api from "../../../Services/api";
import { useHistory } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { PageTitle } from "../CadastrarMedico/styles";
import { ErrorMsg, ErrorBtn, DataList, MyDataGrid } from "../Pacientes/styles";
import { BsEyeFill, BsArrowRight, BsArrowLeft } from "react-icons/bs";
import ReactLoading from "react-loading";
import PageContainer from "../../../Components/PageContainer";
import Tabela from "../../../Components/Tabela";
import { Background, PageContainerDiv, RightDiv } from "../Perfil/styles";
import DataTable from 'react-data-table-component';
import { CgExport } from "react-icons/cg";

export default function Prestadores() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorRepositories, setErrorRepositories] = useState(null);
  const [repositoriesData, setRepositoriesData] = useState([]);
  const [infoPagina, setInfoPagina] = useState(
    {
      has_next: false,
      has_previous: false,
      per_page: 0,
      page: 1,
      total: 0,
    }
  );

  const Export = ({ onExport }) => 
  <button className="bg-[#EFCA04] hover:bg-[#debb02] ml-auto text-black font-bold py-2 px-4 rounded inline-flex items-center cursor-pointer" onClick={e => onExport(e.target.value)}>
    <CgExport className="w-4 h-4 mr-2" size={40} />
    <span>Export CSV</span>
  </button>;
  // const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(repositoriesData)} />, []);

  function convertArrayOfObjectsToCSV(array) {
    let result;
  
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(repositoriesData[0]);
  
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
  
        result += item[key];
        
        ctr++;
      });
      result += lineDelimiter;
    });
  
    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;
  
    const filename = 'export.csv';
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  // const columns = [
  //   { field: "nome", headerName: "Nome", width: 250 },
  //   { field: "telefone", headerName: "Telefone", flex: 1 },
  //   { field: "cpf", headerName: "CPF", flex: 1 },

  //   {
  //     field: "visualizar",
  //     type: "actions",
  //     align: "center",
  //     getActions: (params) => [
  //       <GridActionsCellItem
  //         icon={<BsEyeFill size={20} color="#7A99E3" />}
  //         onClick={() => history.push(`/prestadores/${params.id}`)}
  //         label="Visualizar"
  //       />,
  //     ],
  //   },
  // ];

const colunasTabela = [
    {
      name: 'Nome',
      selector: row => row.nome,
    },
    {
      name: 'CRM',
      selector: row => row.crm,
    },
    {
      name: '',
      maxWidth: '40px',
      selector: row => 
      <BsEyeFill
      className="cursor-pointer" 
      onClick={() => {
        history.push(`/prestadores/${row.id}`);
      }} size={20} color="#7A99E3" 
      />,
    },
  ];

  const fetchRepositoriesData = async (proximaPagina = false, ultimaPagina = false) => {
    setLoading(true);
    setErrorRepositories(null);

    try {
      // Essa rota tem que retornar médicos só com status ativo!
      let modifier = 0;
      if (proximaPagina) {
        modifier = 1;
      }
      if (ultimaPagina) {
        modifier = -1;
      }
      const response = await api.get(`/medicos/listar?page=${infoPagina.page + modifier}&per_page=20`);
      console.log("respostaaaa", response);
      const repositories = response.data.medicos
        .map((item) => ({
          nome: item.nome,
          crm: item.numero_de_registro_profissional,
        }));
      setRepositoriesData(repositories);
      setInfoPagina(
        {
          has_next: response.data.has_next,
          has_previous: response.data.has_previous,
          per_page: response.data.per_page,
          page: response.data.page,
          total: response.data.total,
        }
      );
    } catch (error) {
      setErrorRepositories("Ocorreu um erro ao carregar a página");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRepositoriesData();
  }, []);

  const proximaPagina = async (proxima) => {
    console.log("foi de next");
    if (proxima) {
      if (infoPagina.has_next) {
        fetchRepositoriesData(true, false);
      }
    }
    else {
      if (infoPagina.has_previous) {
        fetchRepositoriesData(false, true);
      }
    }
  };

  function refresh() {
    window.location.reload();
  }

  if (errorRepositories) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Prestadores</PageTitle>
          <ErrorMsg>{errorRepositories}</ErrorMsg>
          <ErrorBtn onClick={(event) => refresh(event)}>
            Tentar novamente
          </ErrorBtn>
        </PageContainer>
      </Page>
    );
  }

  if (loading) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Prestadores</PageTitle>
          <ReactLoading
            type="spin"
            color="#067187"
            height={"30px"}
            width={"30px"}
          />
        </PageContainer>
      </Page>
    );
  }

  return (
    <Page>
      <SidebarMenu />
      
      <PageContainerDiv>
        <Background>
        <RightDiv>
        <PageTitle>Prestadores</PageTitle>
        {/* <Tabela rows={repositoriesData} columns={columns} /> */}
        <div className="flex flex-col w-full space-y-2">
              <Export onExport={() => downloadCSV(repositoriesData)} />
              <div className="w-full max-h-96 overflow-x-auto overflow-y-visible">
                <DataTable
                  columns={colunasTabela}
                  data={repositoriesData}
                  responsive
                  customStyles={{
                    headCells: {
                      style: {
                        backgroundColor: "#EFCA04",
                        fontSize: "16px",
                      },
                    },
                  }}
                />
              </div>

              <div className="flex flex-row w-full justify-between items-center">
                <BsArrowLeft 
                className={infoPagina.has_previous ? "cursor-pointer" : "cursor-not-allowed"}
                onClick={() => proximaPagina(false)} 
                size={50} 
                color={infoPagina.has_previous ? "#EFCA04" : "#ebe0a7"} />
                <p className=" text-[#EFCA04]">
                  {infoPagina.page + "/" + Math.ceil(infoPagina.total/infoPagina.per_page)}
                </p>
                <BsArrowRight 
                className={infoPagina.has_next ? "cursor-pointer" : "cursor-not-allowed"}
                onClick={() => proximaPagina(true)} 
                size={50} 
                color={infoPagina.has_next ? "#EFCA04" : "#ebe0a7"} />
              </div>
            </div>
        </RightDiv>
        </Background>
        </PageContainerDiv>
      
    </Page>
  );
}
