import React from "react";
import EscolherUsuario from "../../../Components/UserChooser";

export default function Permissoes() {
    return (
        <EscolherUsuario
            pageName="Permissões"
            commandText="Você deseja gerenciar as permissões de qual tipo de usuário?"
            information={[
                { path: "/permissoes/prestador", category: "Prestador" },
                { path: "/permissoes/vendedor", category: "Vendedor" },
                { path: "/permissoes/paciente", category: "Paciente" },
            ]}
        />
    );
}
