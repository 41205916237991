import { register } from "swiper/element/bundle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import api from "../../Services/api";
import { useState, useEffect } from "react";

register();

const ContainerMedicosLP = ({ busca }) => {
	const [slidePerView, setSlidePerView] = useState(3);
	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 768) {
				setSlidePerView(1);
			} else {
				setSlidePerView(3);
			}
		}
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const [dados, setDados] = useState([]);
	useEffect(() => {
		const getData = async () => {
			try {
				const response = await api.get("/medico/publicAll");
				console.log(response.data, "data");
				setDados(response.data.medicos);
			} catch (error) {
				console.log(error);
			}
		};
		getData();
	}, []);

	function encontrarEspecialidade(medico) {
		let atende = 0;
		medico.especialidades.forEach((element) => {
			console.log(element.toLowerCase().includes(busca.toLowerCase()));
			if (element.toLowerCase().includes(busca.toLowerCase())) {
				atende += 1;
			}
		});
		if (atende >= 1) {
			return true;
		} else {
			return false;
		}
	}

	const medicosFiltrados = dados.filter((medico) => {
		//filtrar por nome ao digitar

		const atendeBuscaCidade = medico.cidade.toLowerCase().includes(busca.toLowerCase());
		const atendeBuscaBairro = medico.bairro.toLowerCase().includes(busca.toLowerCase());
		const atendeBuscaEstado = medico.estado.toLowerCase().includes(busca.toLowerCase());
		const atendeBuscaNome = medico.name.toLowerCase().includes(busca.toLowerCase());
		const atendeBuscaEspecialidades = encontrarEspecialidade(medico);

		return (
			atendeBuscaCidade ||
			atendeBuscaEstado ||
			atendeBuscaBairro ||
			atendeBuscaNome ||
			atendeBuscaEspecialidades
		);
	});

	return (
		<div className="bg-brancoID py-20">
			{busca && medicosFiltrados.length > 0 && (
				<>
					<h1 className="text-verdeEscuro text-4xl font-bold flex justify-center mb-5">Médicos</h1>
					<Swiper
						slidesPerView={slidePerView}
						pagination={{ clickable: true }}
						navigation
						className=" w-[74%] px-10 pb-20"
					>
						{medicosFiltrados.map((item) => (
							<SwiperSlide className="m-2 flex flex-col items-center">
								<img
									src={item.foto_perfil}
									alt="Médico"
									id="scaleMedicoLP"
									className="h-64 w-72 object-cover rounded-xl transition-transform duration-300 hover:scale-95"
								/>
								<p className="flex text-xl">{item.name}</p>
								<p className="flex text-center">
									{item.cidade}, {item.estado}
								</p>
								<p className="flex">
									Especialidades:&nbsp;
									{item.especialidades.map((value, key) => {
										if (key == 0) {
											return <p className="flex">{value}</p>;
										} else {
											return <p className="flex">,&nbsp;{value}</p>;
										}
									})}
								</p>
							</SwiperSlide>
						))}
					</Swiper>
				</>
			)}
		</div>
	);
};

export default ContainerMedicosLP;
