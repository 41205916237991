import React from "react";
import EscolherUsuario from "../../../Components/UserChooser";

export default function Cadastrar() {
	return (
		<EscolherUsuario
			pageName="Cadastrar"
			commandText="Escolha o tipo de usuário que deseja cadastrar"
			information={[
				{ path: "/cadastrar/vendedor", category: "Vendedor" },
				// { path: "/cadastrar/clinica", category: "Clínica" },
				{ path: "/cadastrar/medico", category: "Prestador" },
			]}
		/>
	);
}
