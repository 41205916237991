import styled from "styled-components";

export const PasswordInput = styled.input`
    padding: 1%;
    width: 35vw;
    height: 35px;
    border-radius: 50px;
    background-color: ${(props) => props.theme.colors.lightGrey};
    font-size: larger;
    color: black;
    padding-left: 20px;
    font-family: 'Lexend', sans-serif;
`;