import Routes from "./Routes/routes";
import { ThemeProvider } from "styled-components";
import theme from "./Theme/index";
import GlobalStyles from "./Theme/global";
import Provider from "./Contexts/index";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Provider>
				<Routes />
			</Provider>
			<GlobalStyles />
		</ThemeProvider>
	);
}

export default App;
