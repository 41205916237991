import React from "react";
import { Status } from "./styles";

const StatusDisplay = ({ status }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "Falha":
        return "Red";
      case "Pendente":
        return "orange";
      case "Consulta Paga":
        return "#00FF00";
      case "Consulta paga ao médico":
        return "#009933";
      default:
        return "black";
    }
  };

  return <Status status={getStatusColor(status)}> {status} </Status>;
};

export default StatusDisplay;
