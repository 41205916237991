import styled from "styled-components";

export const PasswordInfo = styled.p`
    width: 35vw;
    color: ${(props) => props.theme.colors.brancoID};
`;

export const PasswordResetContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5vh;
    padding: 3%;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.roxo};
    color: ${(props) => props.theme.colors.brancoID};
    
`;

export const EmailInput = styled.input`
    padding: 1%;
    width: 35vw;
    height: 35px;
    border-radius: 50px;
    background-color: ${(props) => props.theme.colors.lightGrey};
    font-size: larger;
    color: black;
    padding-left: 20px;
    font-family: 'Lexend', sans-serif;
`;

export const SendButton = styled.button`
    height: 40px;
    width: 15vw;
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.verdeClaro};
    border-radius: 15px;
    font-size: larger;
    font-weight: bold;
    color: ${(props) => props.theme.colors.brancoID};
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Lexend', sans-serif;
`;
