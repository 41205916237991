import Lupa from "../../Assets/lupa.png";
import { NavLink } from "react-router-dom";
import LogoLP from "../LogoLP";

const HeaderLP = () => {
	const scrollToComponent = () => {
		const componenteDesejado = document.getElementById("filtro");
		if (componenteDesejado) {
			componenteDesejado.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<div className="flex items-center justify-between md:px-10 px-2">
			<LogoLP />
			<div
				onClick={scrollToComponent}
				className="cursor-pointer border-none flex justify-between lg:w-[350px] lg:border-solid md:border-2 rounded-3xl py-2 md:px-2 border-roxo hover:border-verdeEscuro"
			>
				<input
					className="cursor-pointer w-[83%] text-lg font-lexend bg-transparent ml-6 hidden lg:flex placeholder-roxo"
					placeholder="Encontre seu Médico"
				/>
				<img className="cursor-pointer object-contain md:mr-5" src={Lupa} alt="Lupa"></img>
			</div>

			<div className="flex gap-2">
				<a
					href="https://docs.google.com/forms/d/e/1FAIpQLScRLmfrmKVQ9lV8Vf5qGm0MuvY4j1G2OTYkRACX_RVIMf6sgQ/viewform"
					target="_blank"
				>
					<button className="cursor-pointer transition duration-300 bg-roxo text-brancoID hover:text-roxo hover:bg-transparent md:py-2 md:px-4 py-1 px-2 rounded-3xl border-solid border-[3px] border-roxo text-sm md:text-base font-lexend font-semibold">
						Seja prestador NOVI
					</button>
				</a>
				<NavLink to="/login">
					<button className="cursor-pointer transition duration-300 hover:text-brancoID hover:bg-roxo border-solid border-[3px] border-roxo md:py-2 md:px-8 py-1 px-4 rounded-3xl text-sm md:text-base font-lexend font-semibold text-roxo bg-transparent">
						Login
					</button>
				</NavLink>
			</div>
		</div>
	);
};

export default HeaderLP;
