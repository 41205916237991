import React, { useEffect, useState } from "react";
import SidebarMenu from "../../../Components/SidebarMenu";
import api from "../../../Services/api";
import { Page } from "../../SignedOut/Cadastro/styles";
import { ErrorText, RegisterInput } from "../CadastrarMedico/styles";
import { PageTitle} from "../Creditos/styles";
import { OptionBtn, OptionTitle } from "../Creditos/styles";
import { InfoBlock, PacientInfo, PacientRow } from "../PacienteUnico/styles";
import {
  Box,
  Boxborda,
  Boxcentro,
  Boxcoluna,
  Boxpad,
  CofirmBtn,
  ConfirmBtn,
  InputSenha,
  SenhaInput,
} from "./styles";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../../Hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageContainer from "../../../Components/PageContainer";

export default function RedefinicaoSenha() {
  const user = useAuth();

  const history = useHistory();
  const [senha, setSenha] = useState("");
  const [confirmaSenha, setConfirmaSenha] = useState("");
  const [invalidFields, setInvalidFields] = useState(false);

  function handleSubmit() {
    console.log("Estou aqui");
    console.log(senha, confirmaSenha);
    if (senha.length < 8) {
      const showToastMessage = () => {
        toast.error(`Sua senha precisa conter no mínimo 8 caracteres!`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessage();
      return;
    } else {
      sendData();
    }
  }

  const sendData = async () => {
    try {
      const data = {
        senha: senha,
      };
      const response = await api.post(`/redefinir-senha`, data);
      const showToastMessage = () => {
        toast.success(`Senha redefinida com sucesso!`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessage();
      history.goBack();
    } catch (error) {
      console.log(error);
      const showToastMessage = () => {
        toast.error(
          `Ocorreu um erro ao tentar redefinir sua senha. Tente novamente!`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      };
      showToastMessage();
    }
  };
  useEffect(() => {
    if (!senha || !confirmaSenha || senha !== confirmaSenha) {
      setInvalidFields(true);
    } else {
      setInvalidFields(false);
    }
  }, [senha, confirmaSenha]);

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Redefinir senha</PageTitle>
        <PacientInfo>
          <Boxborda>
            <Boxcentro>
              <Boxpad pad={1}>
                <OptionTitle>Digite a senha</OptionTitle>
              </Boxpad>
              <Boxpad pad={4}>
                <SenhaInput
                  onChange={(event) => setSenha(event.target.value)}
                  placeholder=" Digite a senha"
                  type="password"
                ></SenhaInput>
              </Boxpad>
              <Boxpad pad={1}>
                <OptionTitle>Confirme a senha</OptionTitle>
              </Boxpad>
              <Boxpad pad={6}>
                <SenhaInput
                  onChange={(event) => setConfirmaSenha(event.target.value)}
                  placeholder=" Digite a senha"
                  type="password"
                ></SenhaInput>
                {(invalidFields && senha) && <ErrorText>Senhas não conferem</ErrorText>}
              </Boxpad>
              <ConfirmBtn
                onClick={(event) => handleSubmit(event.target.value)}
                disabled={invalidFields}
              >
                {" "}
                Redefinir a Senha
              </ConfirmBtn>
            </Boxcentro>
          </Boxborda>
        </PacientInfo>
      </PageContainer>
    </Page>
  );
}
