import styled from "styled-components";
import Select from "react-select";

export const CreditsInput = styled.input`
    padding: 1%;
    width: 70px;
    height: 25px;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.lightGrey};
    font-size: larger;
    color: black;
    padding-left: 20px;
`;

export const TypeSelect = styled.select`
    background-color: ${(props) => props.theme.colors.lightBlue};
    height: 40px;
    width: 200px;
    border-radius: 5px;
    font-size: medium;
    padding: 5px;
`;

export const OptionRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 8vh;
    gap: ${(props) => props.gap};
    align-items: center;
`;

export const TransferButton = styled.button`
    height: 35px;
    width: 150px;
    cursor: pointer;
    background-color: ${props => props.theme.colors.mediumBlue};
    border-radius: 5px;
    font-size: larger;
`;


export const BoxColuna = styled.div`
display: flex;
flex-direction: column;
padding-bottom: 1vh;
`

export const BoxColuna2 = styled.div`
display: flex;
flex-direction: column;
padding-top: 1vh;
`


export const CreditsDiv = styled.div`
    padding: 1%;
    width: 70px;
    height: 50px;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.lightGrey};
    font-size: larger;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
`;


export const InputAsync = styled(Select)`
    @media (min-width: 50vw){
    flex-direction: row;
  }
`;