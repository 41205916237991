import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Card = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 120px;
	gap: 30%;
	height: 205px;
	background: #eff1f3;
	border-radius: 15px;
	align-self: stretch;
`;
