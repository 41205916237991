import React, { useEffect, useState } from "react";
import { MenuItem, MenuLink, Menu } from "./styles";
import { PageOptions } from "./styles";

export default function MenuMetricas() {
	const onPageStyle = {
		color: "#f3f3f3",
		backgroundColor: "#663780",
	};

	return (
		<Menu>
			<MenuItem>
				<MenuLink to={"/metricas/acessos"} activeStyle={onPageStyle}>
					Acessos
				</MenuLink>
			</MenuItem>
			<MenuItem>
				<MenuLink to={"/metricas/creditos"} activeStyle={onPageStyle}>
					Créditos
				</MenuLink>
			</MenuItem>
			<MenuItem>
				<MenuLink to={"/metricas/users"} activeStyle={onPageStyle}>
					Informações dos Usuários
				</MenuLink>
			</MenuItem>
		</Menu>
	);
}
