import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";

export const MyDataGrid = styled(DataGrid)`
  width: 80%;
  height: 100%;
  border-radius: 20px;
  max-height: 450px;
  color: white;
  background-color: white;
  
  
`;
