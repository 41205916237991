import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useAuth } from "../../../Hooks/useAuth";
import Sidebar from "../../../Components/Sidebar";
import { Page, PageContainer, InputContainer, PageTitle } from "../Cadastro/styles";
import {
	LoginContainer,
	LoginButton,
	LoginInput,
	RedirectContainer,
	RedirectButton,
	ErrorText,
	LoginRow,
	ButtonRow,
} from "./styles";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Input from "../../../Components/Input";

export default function Login() {
	const history = useHistory();
	const [invalidFields, setInvalidFields] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loginError, setLoginError] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const { signIn } = useAuth();

	const initialValues = {
		Email: "",
		Senha: "",
	};
	const validationSchema = Yup.object({
		Email: Yup.string().email("E-mail Inválido").required("Campo Obrigatório"),
		Senha: Yup.string().required("Campo Obrigatório"),
	});

	const handleSubmit = async (values) => {
		console.log("sadasd", values);
		const data = {
			email: values.Email,
			senha: values.Senha,
		};

		console.log(data);
		signIn(data, setLoading, setLoginError, history);
		setSubmitting(false);
	};

	return (
		<div>
			<Page>
				<Sidebar />
				<PageContainer>
					<LoginContainer>
						<PageTitle>Login</PageTitle>

						<InputContainer>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={handleSubmit}
							>
								{({ values, errors, touched, handleChange, isSubmitting }) => (
									<Form>
										<LoginRow>
											<Input
												name="Email"
												type="email"
												required
												placeholder="Email"
												onChange={handleChange}
												value={values.Email}
											></Input>
										</LoginRow>
										<LoginRow>
											<Input
												name="Senha"
												type="password"
												required
												placeholder="Senha"
												onChange={handleChange}
												value={values.Senha}
											></Input>
										</LoginRow>
										<ButtonRow>
											<LoginButton type="submit" disabled={isSubmitting}>
												{loading ? (
													<ReactLoading
														type="spin"
														color="#B2EBF2"
														height={"30px"}
														width={"30px"}
													/>
												) : (
													"Entrar"
												)}
											</LoginButton>
										</ButtonRow>
										<ErrorText>{loginError ? "Email e/ou senha inválidos" : ""}</ErrorText>
									</Form>
								)}
							</Formik>
						</InputContainer>

						<RedirectContainer>
							{/* <NavLink to="/cadastro">
                            <RedirectButton>Cadastre-se</RedirectButton>
                        </NavLink> */}
							<NavLink to="/esqueci-minha-senha">
								<RedirectButton>Esqueci minha senha</RedirectButton>
							</NavLink>
						</RedirectContainer>
					</LoginContainer>
				</PageContainer>
			</Page>
		</div>
	);
}

/*
 <LoginInput
                            placeholder="Email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        <LoginInput
                            placeholder="Senha"
                            type="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
*/
