import styled from "styled-components";

export const PacientInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7vh;
`;

export const PacientRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15vh;
`;

export const InfoBlock = styled.p`
  display: flex;
  flex-direction: column;
  gap: 1vh;
  width: 25vw;
`;

export const InfoTitle = styled.h3`
  color: ${(props) => props.theme.colors.verdeClaro};
  font-family: 'Cairo', sans;
  
`;

export const Info = styled.p`
  font-size: large;
  display: flex;
  flex-direction: column;
  font-size: 120%;
  color:${(props) => props.theme.colors.brancoID};
`;
