import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SidebarMenu from "../../../Components/SidebarMenu";
import api from "../../../Services/api";
import { Page } from "../../SignedOut/Cadastro/styles";
import { PageTitle, RegisterInput } from "../CadastrarMedico/styles";
import {
  Option,
  OptionBtn,
  OptionTitle,
  PageOptions,
} from "../Creditos/styles";
import { Boxcentral } from "./styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageContainer from "../../../Components/PageContainer";

export default function ValorCredito() {
  const history = useHistory();
  const [valor, setValor] = useState(0);
  const [valorAtual, setValorAtual] = useState(0);

  async function sendData() {
    try {
      const data = {
        valor: parseFloat(valor),
      };
      console.log(typeof data.valor);
      console.log(data);
      const response = await api.patch("/creditos", data);
      const showToastMessage = () => {
        toast.success(`Valor do crédito alterado com sucesso com sucesso!`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessage();
      history.push("/creditos");
    } catch (error) {
      console.log("\nerro data SendData qtd e valor", error.response);
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get("/creditos");
        const dados = response.data;
        console.log("response", response.data);
        setValorAtual(dados.valor_credito);
      } catch (error) {
        console.log(error.response.data);
        console.log("PQP deu errooo");
      }
    };
    getData();
  }, []);

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Valor do Crédito</PageTitle>
        <PageOptions>
          <Option>
            <OptionTitle>Digite o valor do creditos</OptionTitle>
            <RegisterInput
              placeholder="Digite o Valor"
              onChange={(event) => setValor(event.target.value)}
            ></RegisterInput>
          </Option>
          <Option>
            <OptionTitle> 1 Crédito valem R$ {valorAtual}</OptionTitle>
          </Option>
          <Boxcentral>
            <OptionBtn onClick={() => sendData()}>
              {" "}
              Confirmar Mudancas{" "}
            </OptionBtn>
          </Boxcentral>
        </PageOptions>
      </PageContainer>
    </Page>
  );
}
