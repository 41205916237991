const theme = {
  colors: {
    lightBlue: "#B2EBF2",
    mediumBlue: "#81B9BF",
    greenBlue: "#6AB3BC",
    darkBlue: "#7A99E3",
    lightGrey: "#F5F5F5",
    grey: "#C4C4C4",
    red: "#E42929",
    amarelo: "#EFCA04",
    roxo:"#663780",
    verdeEscuro:"#067187",
    verdeClaro:"#49B69B",
    brancoID:"#EFF1F3",
    pretoID:"#1E1E24",
  },
};

export default theme;
