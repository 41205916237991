const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const dataCompraSemanaDefault = [
	{
		name: "Créditos Comprados",
		data: [
			{
				x: "Semana 1",
				y: 50,
			},
			{
				x: "Semana 2",
				y: 54,
			},
			{
				x: "Semana 3",
				y: 74,
			},
			{
				x: "Semana 4",
				y: 81,
			},
		],
	},
];

export const optionsCompra = {
	options: {
		colors: ["#663780"],
		chart: {
			height: 350,
			type: "line",
			zoom: {
				enabled: false,
			},
			fontFamily: "'Lexend'",
			foreColor: "#535353",
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "straight",
			width: 3,
		},
		title: {
			text: "Número de créditos comprados",
			align: "left",
			style: {
				color: "#1E1E24",
				fontStyle: "normal",
				fontWeight: 400,
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		grid: {
			row: {
				colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
				opacity: 0.5,
			},
		},
		xaxis: {
			type: "category",
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "12px",
					lineHeight: "15px",
					textAlign: "center",
				},
			},
		},
		yaxis: {
			show: true,
			forceNiceScale: true,
			stepSize: 500,
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "11px",
					lineHeight: "14px",
					textAlign: "right",
				},
			},
		},
	},
};

export const dataUsoSemanaDefault = [
	{
		name: "Créditos Utilizados",
		data: [
			{
				x: "Semana 1",
				y: 0,
			},
			{
				x: "Semana 2",
				y: 0,
			},
			{
				x: "Semana 3",
				y: 0,
			},
			{
				x: "Semana 4",
				y: 0,
			},
		],
	},
];

export const optionsUso = {
	options: {
		colors: ["#663780"],
		chart: {
			height: 350,
			type: "line",
			zoom: {
				enabled: false,
			},
			fontFamily: "'Lexend'",
			foreColor: "#535353",
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "straight",
			width: 3,
		},
		title: {
			text: "Número de créditos utilizados",
			align: "left",
			style: {
				color: "#1E1E24",
				fontStyle: "normal",
				fontWeight: 400,
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		grid: {
			row: {
				colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
				opacity: 0.5,
			},
		},
		xaxis: {
			categories: [
				"Jan",
				"Fev",
				"Mar",
				"Abr",
				"Mai",
				"Jun",
				"Jul",
				"Ago",
				"Set",
				"Out",
				"Nov",
				"Dez",
			],
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "12px",
					lineHeight: "15px",
					textAlign: "center",
				},
			},
		},
		yaxis: {
			show: true,
			forceNiceScale: true,
			stepSize: 300,
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "11px",
					lineHeight: "14px",
					textAlign: "right",
				},
			},
		},
	},
};

export const dataEspecialidadesDefault = [
	{
		name: "Especialidade",
		data: [
			{
				x: "Jan",
				y: 300,
			},
			{
				x: "Fev",
				y: 354,
			},
			{
				x: "Mar",
				y: 270,
			},
			{
				x: "Abr",
				y: 410,
			},
			{
				x: "Mai",
				y: 500,
			},
			{
				x: "Jun",
				y: 399,
			},
			{
				x: "Jul",
				y: 489,
			},
			{
				x: "Ago",
				y: 503,
			},
			{
				x: "Set",
				y: 459,
			},
		],
	},
];

export const optionsEspecialidade = {
	options: {
		colors: ["#663780"],
		chart: {
			zoom: {
				enabled: false,
			},
			fontFamily: "'Lexend'",
			foreColor: "#535353",
		},
		plotOptions: {
			bar: {
				borderRadius: 5,
				borderRadiusApplication: "end",
				columnWidth: "50%",
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			show: true,
			width: 2,
			colors: ["transparent"],
		},

		title: {
			text: "Número de créditos utilizados",
			align: "left",
			style: {
				color: "#1E1E24",
				fontStyle: "normal",
				fontWeight: 400,
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		grid: {
			row: {
				colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
				opacity: 0.5,
			},
		},
		xaxis: {
			type: "category",
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "12px",
					lineHeight: "15px",
					textAlign: "center",
				},
			},
		},
		yaxis: {
			show: true,
			forceNiceScale: true,
			stepSize: 300,
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "11px",
					lineHeight: "14px",
					textAlign: "right",
				},
			},
		},
	},
};
