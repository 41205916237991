import React from "react";
import { InfoBlock, InfoTitle, RecusarButton } from "./styles";

const BlockButton = (props) => {
  return (
    <InfoBlock>
      <InfoTitle>Bloquear usuário:</InfoTitle>
      <RecusarButton {...props}>Bloquear</RecusarButton>
    </InfoBlock>
  );
};

export default BlockButton;
