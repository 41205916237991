import styled from "styled-components";

export const ProviderInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10vh;
`;

export const ProviderRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15vh;
`;