import React, { useEffect, useState } from "react";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { MenuItem, PageTitle, Option, MenuLink, Menu, OptionTitle } from "./styles";
import PageContainer from "../../../Components/PageContainer";
import Chart from "react-apexcharts";
import { PageOptions } from "./styles";
import api from "../../../Services/api";
import {
	downloadsInfo,
	optionsAcessosMes,
	optionsDownloadsMes,
	optionsPacientes,
	optionsPrestadores,
} from "./data";
import MenuMetricas from "../../../Components/MenuMetricas";
import MetricInforCard from "../../../Components/MetricInfoCard";

export default function MetricasAcessos() {
	const [titularesTotal, setTitularesTotal] = useState();
	const [mediaAfiliados, setMediaAfiliados] = useState();
	const [afiliadosTotal, setAfiliadosTotal] = useState();
	const [titularesMes, setTitularesMes] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
	const [mediaAfiliadosMes, setMediaAfiliadosMes] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
	const [afiliadosMes, setAfiliadosMes] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
	const [medicosTotal, setMedicosTotal] = useState();
	const [medicosMes, setMedicosMes] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
	const [dadosAcessosSW, setDadosAcessosSW] = useState(
	[
		{
			name: "Acessos",
			data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		},
	]);
	const [optionsGraficoAcessos, setOptionsGraficoAcessos] = useState(
		{
			colors: ["#663780"],
			chart: {
				height: 350,
				type: "line",
				zoom: {
					enabled: false,
				},
				fontFamily: "'Lexend'",
				foreColor: "#535353",
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: "straight",
				width: 3,
			},
			title: {
				text: "Número de acessos por mês",
				align: "left",
				style: {
					color: "#1E1E24",
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "16px",
					lineHeight: "20px",
				},
			},
			grid: {
				row: {
					colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
					opacity: 0.5,
				},
			},
			xaxis: {
				categories: [
					"Jan",
					"Fev",
					"Mar",
					"Abr",
					"Mai",
					"Jun",
					"Jul",
					"Ago",
					"Set",
					"Out",
					"Nov",
					"Dez",
				],
				labels: {
					style: {
						fontStyle: "normal",
						fontWeight: 400,
						fontSize: "12px",
						lineHeight: "15px",
						textAlign: "center",
					},
				},
			},
			yaxis: {
				show: true,
				forceNiceScale: true,
				stepSize: 300,
				labels: {
					style: {
						fontStyle: "normal",
						fontWeight: 400,
						fontSize: "11px",
						lineHeight: "14px",
						textAlign: "right",
					},
				},
			},
		}
	);

	useEffect(() => {
		const getTitulares = async () => {
			try {
				const response = await api.get("/pacientes/total");
				const total_titulares = response.data.contagem;
				setTitularesTotal(total_titulares);
			} catch (error) {
				console.log(error);
			}
		};
		const getAfiliatesAvarage = async () => {
			try {
				const response = await api.get("/afiliado/media");
				const media_afiliado = response.data.media_afiliados_por_paciente;
				setMediaAfiliados(media_afiliado);
			} catch (error) {
				console.log(error);
			}
		};
		const getAfiliates = async () => {
			try {
				const response = await api.get("/afiliado/total");
				const total_afiliado = response.data.contagem;
				setAfiliadosTotal(total_afiliado);
			} catch (error) {
				console.log(error);
			}
		};
		const getMonthlyTitulares = async () => {
			try {
				const response = await api.get("/pacientes/total_12_meses");
				const total_titulares_mes = response.data;
				setTitularesMes(total_titulares_mes);
			} catch (error) {
				console.log(error);
			}
		};
		const getMonthlyAfiliates = async () => {
			try {
				const response = await api.get("/afiliado/total_12_meses");
				const total_afiliado_mes = response.data;
				setAfiliadosMes(total_afiliado_mes);
			} catch (error) {
				console.log(error);
			}
		};
		const getPrestadores = async () => {
			try {
				const response = await api.get("/medico/total");
				const total_medicos = response.data.contagem;
				setMedicosTotal(total_medicos);
			} catch (error) {
				console.log(error);
			}
		};
		const getMonthlyPrestadores = async () => {
			try {
				const response = await api.get("/medico/total_12_meses");
				const total_medico_mes = response.data;
				setMedicosMes(total_medico_mes);
			} catch (error) {
				console.log(error);
			}
		};
		const getDadosMetricas = async () => {
			try {
				const response = await api.get("/users/acessos-sw");
				const total_dados = response.data;
				let listaTemp = [];
				let listaNomeMesesTemp = [];
				for (const intervalo in total_dados) {
					listaTemp.push(total_dados[intervalo]);
					const numMes = intervalo.substring(5, 7);
					const dataFormatada = new Date(2024, +numMes - 1, 5);
					const nomeMes = dataFormatada.toLocaleString('pt-BR', { month: 'long' });
					const nomeMesEncurtado = String(nomeMes).charAt(0).toUpperCase() + String(nomeMes).slice(1, 3);
					listaNomeMesesTemp.push(nomeMesEncurtado);
				}
				setDadosAcessosSW(
				[
					{
						name: "Acessos",
						data: listaTemp,
					},
				]);
				setOptionsGraficoAcessos(
					{
						colors: ["#663780"],
						chart: {
							height: 350,
							type: "line",
							zoom: {
								enabled: false,
							},
							fontFamily: "'Lexend'",
							foreColor: "#535353",
						},
						dataLabels: {
							enabled: false,
						},
						stroke: {
							curve: "straight",
							width: 3,
						},
						title: {
							text: "Número de acessos por mês",
							align: "left",
							style: {
								color: "#1E1E24",
								fontStyle: "normal",
								fontWeight: 400,
								fontSize: "16px",
								lineHeight: "20px",
							},
						},
						grid: {
							row: {
								colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
								opacity: 0.5,
							},
						},
						xaxis: {
							categories: listaNomeMesesTemp,
							labels: {
								style: {
									fontStyle: "normal",
									fontWeight: 400,
									fontSize: "12px",
									lineHeight: "15px",
									textAlign: "center",
								},
							},
						},
						yaxis: {
							show: true,
							forceNiceScale: true,
							stepSize: 300,
							labels: {
								style: {
									fontStyle: "normal",
									fontWeight: 400,
									fontSize: "11px",
									lineHeight: "14px",
									textAlign: "right",
								},
							},
						},
					}
				);
				console.log("métricas:");
				console.log(total_dados);
			} catch (error) {
				console.log("deu pau nas métricas");
				console.log(error);
			}
		};
		getTitulares();
		getAfiliatesAvarage();
		getAfiliates();
		getMonthlyTitulares();
		getMonthlyAfiliates();
		getPrestadores();
		getMonthlyPrestadores();
		getDadosMetricas();
	}, []);

	const pacientesInfo = [
		{
			title: "Titulares Cadastrados",
			value: titularesTotal,
			icon: (
				<svg
					width="66"
					height="66"
					viewBox="0 0 66 66"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						stroke="#4d4d4d"
						strokeWidth={2}
						d="M32.6426 27.7148C35.7837 27.7148 38.3301 25.1685 38.3301 22.0273C38.3301 18.8862 35.7837 16.3398 32.6426 16.3398C29.5015 16.3398 26.9551 18.8862 26.9551 22.0273C26.9551 25.1685 29.5015 27.7148 32.6426 27.7148Z"
						fill="#4D4D4D"
					/>
					<path
						stroke="#4d4d4d"
						strokeWidth={2}
						d="M32.6424 40.7148C24.5987 40.7148 16.1216 44.669 16.1216 46.4023V49.3815H49.1632V46.4023C49.1632 44.669 40.6862 40.7148 32.6424 40.7148Z"
						fill="#4D4D4D"
					/>
					<path
						d="M32.6427 35.5684C25.4115 35.5684 10.9761 39.1975 10.9761 46.4017V54.5267H54.3094V46.4017C54.3094 39.1975 39.874 35.5684 32.6427 35.5684ZM49.1636 49.3809H16.1219V46.4017C16.1219 44.6684 24.599 40.7142 32.6427 40.7142C40.6865 40.7142 49.1636 44.6684 49.1636 46.4017V49.3809ZM32.6427 32.86C38.6282 32.86 43.4761 28.0121 43.4761 22.0267C43.4761 16.0413 38.6282 11.1934 32.6427 11.1934C26.6573 11.1934 21.8094 16.0413 21.8094 22.0267C21.8094 28.0121 26.6573 32.86 32.6427 32.86ZM32.6427 16.3392C35.7844 16.3392 38.3302 18.885 38.3302 22.0267C38.3302 25.1684 35.7844 27.7142 32.6427 27.7142C29.5011 27.7142 26.9552 25.1684 26.9552 22.0267C26.9552 18.885 29.5011 16.3392 32.6427 16.3392Z"
						fill="#4D4D4D"
					/>
				</svg>
			),
		},
		{
			title: "Média dependentes",
			value: mediaAfiliados,
			icon: (
				<svg
					width="66"
					height="34"
					viewBox="0 0 66 34"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M33.1426 18.8906C37.5572 18.8906 41.4572 19.9469 44.6259 21.3281C47.5509 22.6281 49.3926 25.5531 49.3926 28.7219V33.1094H16.8926V28.749C16.8926 25.5531 18.7342 22.6281 21.6592 21.3552C24.828 19.9469 28.728 18.8906 33.1426 18.8906ZM11.4759 19.5677C14.4551 19.5677 16.8926 17.1302 16.8926 14.151C16.8926 11.1719 14.4551 8.73437 11.4759 8.73437C8.49674 8.73437 6.05924 11.1719 6.05924 14.151C6.05924 17.1302 8.49674 19.5677 11.4759 19.5677ZM14.5363 22.5469C13.5342 22.3844 12.5322 22.276 11.4759 22.276C8.79466 22.276 6.24883 22.8448 3.94674 23.8469C1.94258 24.7135 0.642578 26.6635 0.642578 28.8573V33.1094H12.8301V28.749C12.8301 26.501 13.453 24.3885 14.5363 22.5469ZM54.8092 19.5677C57.7884 19.5677 60.2259 17.1302 60.2259 14.151C60.2259 11.1719 57.7884 8.73437 54.8092 8.73437C51.8301 8.73437 49.3926 11.1719 49.3926 14.151C49.3926 17.1302 51.8301 19.5677 54.8092 19.5677ZM65.6426 28.8573C65.6426 26.6635 64.3426 24.7135 62.3384 23.8469C60.0363 22.8448 57.4905 22.276 54.8092 22.276C53.753 22.276 52.7509 22.3844 51.7488 22.5469C52.8322 24.3885 53.4551 26.501 53.4551 28.749V33.1094H65.6426V28.8573ZM33.1426 0.609375C37.6384 0.609375 41.2676 4.23854 41.2676 8.73437C41.2676 13.2302 37.6384 16.8594 33.1426 16.8594C28.6467 16.8594 25.0176 13.2302 25.0176 8.73437C25.0176 4.23854 28.6467 0.609375 33.1426 0.609375Z"
						fill="#4D4D4D"
					/>
				</svg>
			),
		},
		{
			title: "Dependentes cadastrados",
			value: afiliadosTotal,
			icon: (
				<svg
					width="58"
					height="40"
					viewBox="0 0 58 40"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M33.3099 18.98C37.7602 18.98 41.6918 20.0448 44.8862 21.4372C47.8349 22.7477 49.6914 25.6964 49.6914 28.8908V33.3138H16.9284V28.9181C16.9284 25.6964 18.785 22.7477 21.7336 21.4645C24.928 20.0448 28.8596 18.98 33.3099 18.98ZM11.4679 19.6626C14.4712 19.6626 16.9284 17.2053 16.9284 14.202C16.9284 11.1988 14.4712 8.74154 11.4679 8.74154C8.46461 8.74154 6.00738 11.1988 6.00738 14.202C6.00738 17.2053 8.46461 19.6626 11.4679 19.6626ZM14.5531 22.6658C13.5429 22.502 12.5327 22.3928 11.4679 22.3928C8.76494 22.3928 6.1985 22.9662 3.87778 23.9764C1.8574 24.85 0.546875 26.8158 0.546875 29.0273V33.3138H12.833V28.9181C12.833 26.652 13.461 24.5224 14.5531 22.6658ZM33.3099 0.550781C37.8421 0.550781 41.5007 4.20932 41.5007 8.74154C41.5007 13.2738 37.8421 16.9323 33.3099 16.9323C28.7777 16.9323 25.1192 13.2738 25.1192 8.74154C25.1192 4.20932 28.7777 0.550781 33.3099 0.550781Z"
						fill="#4D4D4D"
					/>
					<path
						d="M52.4093 22.456L42.3549 32.5104L38.9047 29.0602C38.6703 28.8258 38.3524 28.6941 38.0209 28.6941C37.6893 28.6941 37.3714 28.8258 37.137 29.0602L35.6923 30.5049C35.4579 30.7393 35.3262 31.0573 35.3262 31.3888C35.3262 31.7203 35.4579 32.0383 35.6923 32.2727L41.471 38.0514C41.9592 38.5396 42.7506 38.5396 43.2388 38.0514L55.6218 25.6684C56.1099 25.1803 56.11 24.3888 55.6218 23.9006L54.1771 22.456C53.9427 22.2215 53.6247 22.0898 53.2932 22.0898C52.9617 22.0898 52.6438 22.2215 52.4093 22.456Z"
						fill="#4D4D4D"
						stroke="#EFF1F3"
						stroke-width="2.5"
						stroke-linejoin="round"
					/>
				</svg>
			),
		},
	];

	const prestadoresInfo = [
		{
			title: "Total",
			value: medicosTotal,
			icon: (
				<svg
					width="66"
					height="34"
					viewBox="0 0 66 34"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M33.1426 18.8906C37.5572 18.8906 41.4572 19.9469 44.6259 21.3281C47.5509 22.6281 49.3926 25.5531 49.3926 28.7219V33.1094H16.8926V28.749C16.8926 25.5531 18.7342 22.6281 21.6592 21.3552C24.828 19.9469 28.728 18.8906 33.1426 18.8906ZM11.4759 19.5677C14.4551 19.5677 16.8926 17.1302 16.8926 14.151C16.8926 11.1719 14.4551 8.73437 11.4759 8.73437C8.49674 8.73437 6.05924 11.1719 6.05924 14.151C6.05924 17.1302 8.49674 19.5677 11.4759 19.5677ZM14.5363 22.5469C13.5342 22.3844 12.5322 22.276 11.4759 22.276C8.79466 22.276 6.24883 22.8448 3.94674 23.8469C1.94258 24.7135 0.642578 26.6635 0.642578 28.8573V33.1094H12.8301V28.749C12.8301 26.501 13.453 24.3885 14.5363 22.5469ZM54.8092 19.5677C57.7884 19.5677 60.2259 17.1302 60.2259 14.151C60.2259 11.1719 57.7884 8.73437 54.8092 8.73437C51.8301 8.73437 49.3926 11.1719 49.3926 14.151C49.3926 17.1302 51.8301 19.5677 54.8092 19.5677ZM65.6426 28.8573C65.6426 26.6635 64.3426 24.7135 62.3384 23.8469C60.0363 22.8448 57.4905 22.276 54.8092 22.276C53.753 22.276 52.7509 22.3844 51.7488 22.5469C52.8322 24.3885 53.4551 26.501 53.4551 28.749V33.1094H65.6426V28.8573ZM33.1426 0.609375C37.6384 0.609375 41.2676 4.23854 41.2676 8.73437C41.2676 13.2302 37.6384 16.8594 33.1426 16.8594C28.6467 16.8594 25.0176 13.2302 25.0176 8.73437C25.0176 4.23854 28.6467 0.609375 33.1426 0.609375Z"
						fill="#4D4D4D"
					/>
				</svg>
			),
		},
	];

	const arrayTransform = (series) => {
		const data = [];
		for (const [key, value] of Object.entries(series)) {
			data.push({ x: key, y: value });
		}
		return data;
	};

	return (
		<Page>
			<SidebarMenu />
			<PageContainer>
				<PageTitle>Métricas</PageTitle>
				<PageOptions>
					<MenuMetricas></MenuMetricas>
					<Option>
						<OptionTitle>Site</OptionTitle>
						<Chart
							options={optionsGraficoAcessos}
							series={dadosAcessosSW}
							type="line"
							height={350}
						></Chart>
					</Option>
					<Option>
						<OptionTitle>Downloads por mês</OptionTitle>
						<MetricInforCard data={downloadsInfo}></MetricInforCard>
						<Chart
							options={optionsDownloadsMes.options}
							series={optionsDownloadsMes.series}
							type="line"
							height={350}
						></Chart>
					</Option>
					<Option>
						<OptionTitle>Pacientes</OptionTitle>
						<MetricInforCard data={pacientesInfo}></MetricInforCard>
						<Chart
							options={optionsPacientes.options}
							series={[
								{
									name: "Titulares cadastrados",
									data: arrayTransform(titularesMes),
								},
								{
									name: "Dependentes cadastrados",
									data: arrayTransform(afiliadosMes),
								},
							]}
							type="line"
							height={350}
						></Chart>
					</Option>
					<Option>
						<OptionTitle>Prestadores</OptionTitle>
						<MetricInforCard data={prestadoresInfo}></MetricInforCard>
						<Chart
							options={optionsPrestadores.options}
							series={[
								{
									name: "Prestadores",
									data: arrayTransform(medicosMes),
								},
							]}
							type="line"
							height={350}
						></Chart>
					</Option>
				</PageOptions>
			</PageContainer>
		</Page>
	);
}
