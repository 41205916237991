import React from "react";
import PageContainer from "../PageContainer";
import SidebarMenu from "../SidebarMenu";
import {
  ProviderInfo,
  ProviderRow,
  InfoBlock,
  Info,
  InfoTitle,
  Page,
  RecusarButton,
} from "./styles";
import { PageTitle} from "../../Pages/SignedIn/Creditos/styles";

const InfosVendedor = ({ prestador, buttons }) => {
  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Vendedor</PageTitle>
        <ProviderInfo>
          <ProviderRow>
            <InfoBlock>
              <InfoTitle>Nome:</InfoTitle>
              <Info>{prestador?.nome}</Info>
            </InfoBlock>
          </ProviderRow>
          <ProviderRow>
            <InfoBlock>
              <InfoTitle>Email:</InfoTitle>
              <Info>{prestador?.email}</Info>
            </InfoBlock>
          </ProviderRow>
          <ProviderRow>
            <InfoBlock>
              <InfoTitle>Créditos:</InfoTitle>
              <Info>{prestador?.medico?.creditos} crédito(s)</Info>
            </InfoBlock>
          </ProviderRow>

          <ProviderRow>{buttons}</ProviderRow>
        </ProviderInfo>
      </PageContainer>
    </Page>
  );
};

export default InfosVendedor;
