import Lupa from "../../Assets/lupa.png";

const InputLP = ({ setBusca, busca }) => {
	return (
		<div>
			<label className="flex justify-center mb-5 text-xl font-bold text-verdeEscuro" for="filtro">
				Encontre seu Médico
			</label>
			<div className=" flex justify-between md:w-[460px] border-solid border-2 rounded-3xl py-2 px-2 border-roxo hover:border-verdeEscuro">
				<input
					id="filtro"
					onChange={(event) => setBusca(event.target.value)}
					value={busca}
					className="w-[83%] text-base font-lexend bg-transparent ml-6 placeholder-roxo"
					placeholder="Digite o bairro, cidade, estado ou especialidade"
				/>
				<img className="cursor-pointer object-contain mr-5" src={Lupa} alt="Lupa"></img>
			</div>
		</div>
	);
};

export default InputLP;
