import React, { useState, useEffect } from "react";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Link, useParams } from "react-router-dom";
import api from "../../../Services/api";
import { useAuth } from "../../../Hooks/useAuth";
import MainButton from "../../../Components/MainButton";
import {
  Background,
  Content,
  Info,
  InfoBlock,
  InfoTitle,
  PageContainer,
  PageContainerDiv,
  PageTitle,
  Redefini,
  RightDiv,
} from "./styles";

function PerfilAdministrador() {
  const { user } = useAuth();
  const [dados, setDados] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get(`/user/${user.id}`);
        setDados(response.data);
      } catch (error) {
        console.log(error, "Erro no perfil");
      }
    };

    fetchUserData();
  }, [user.id]);

  return (
    <PageContainerDiv>
      <SidebarMenu />
      <Background>
        <RightDiv>
          <PageTitle>Perfil</PageTitle>
          <Content>
            <InfoBlock>
              <InfoTitle>Nome:</InfoTitle>

              <Info>{dados.nome}</Info>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Email:</InfoTitle>

              <Info>{dados.email}</Info>
            </InfoBlock>
          </Content>

          <Redefini>
            <Link to="/redefinicao-de-senha">
              <MainButton>Redefinir senha</MainButton>
            </Link>
          </Redefini>
        </RightDiv>
      </Background>
    </PageContainerDiv>
  );
}

export default PerfilAdministrador;
