import styled from "styled-components";

export const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 7vh;
	align-items: center;
	margin-left: 20vw;
	padding-bottom: 5%;
`;

export const RegisterContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3vh;
	background-color: ${(props) => props.theme.colors.roxo};
	padding: 3%;
	border-radius: 10px;
	width: 50vw;
	color: ${(props) => props.theme.colors.brancoID};

	margin-bottom: 5%;
`;

export const PageTitle = styled.h1`
	font-weight: bold;
	color: ${(props) => props.theme.colors.amarelo};
	border-bottom: 2px solid ${(props) => props.theme.colors.pretoID};
	padding-top: 3%;
	font-family: "Cairo", sans-serif;
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 3vh;
	align-items: center;
`;

export const RegisterInput = styled.input`
	padding: 1%;
	width: 50vw;
	height: 25px;
	border-radius: 50px;
	background-color: ${(props) => props.theme.colors.lightGrey};
	font-size: larger;
	color: black;
	padding-left: 20px;
`;

export const ErrorText = styled.h4`
	color: ${(props) => props.theme.colors.red};
	text-align: center;
`;

export const RegisterButton = styled.button`
	height: 40px;
	width: 20vw;
	cursor: pointer;
	background-color: ${(props) => props.theme.colors.verdeClaro};
	border-radius: 15px;
	font-size: larger;
	font-weight: bold;
	color: ${(props) => props.theme.colors.brancoID};
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ColumnRow1 = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;
