import styled from "styled-components";

export const PageOptions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4vh;
`;

export const PageTitle = styled.h1`
  font-weight: bold;
  color: ${(props) => props.theme.colors.verdeEscuro};
  border-bottom: 2px solid ${(props) => props.theme.colors.pretoID};
  padding-top: 3%;
  font-family: 'Cairo', sans-serif;
`;

export const Option = styled.div`
    display: flex;
    flex-direction: column;
    width: 60vw;
    gap: 3vh;
    padding-bottom: 4vh;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey};
`;

export const OptionTitle = styled.h2`
    color: ${(props) => props.theme.colors.roxo};
    font-weight: normal;
    font-family: 'Cairo', sans-serif;
`;

export const OptionBtn = styled.button`
    cursor: pointer;
    height: max-content;
    width: max-content;
    font-size: medium;
    padding: 1.5%;
    margin-left: 8vh;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.roxo};
    color: ${(props) => props.theme.colors.brancoID};
    font-family: 'Lexend', sans-serif;
`;

export const CreditsLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const CreditsText = styled.p`
    font-size: large;
    padding: 1.5%;
    margin-left: 8vh;
`;
