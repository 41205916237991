import React, { useState } from "react";
import PageContainer from "../PageContainer";
import SidebarMenu from "../SidebarMenu";
import {
  ProviderInfo,
  ProviderRow,
  InfoBlock,
  Info,
  InfoTitle,
  Page,
  
  RecusarButton,
  ImageContainer,
  InputPrestador,
  ButtonPrestador,
} from "./styles";
import { PageTitle} from "../../Pages/SignedIn/Creditos/styles";
import api from "../../Services/api";
import { toast, ToastContainer } from "react-toastify";

const InfosMedico = ({ prestador, buttons }) => {
  console.log(prestador.img_url);
  const [medico, setMedico] = useState(null);
  const [novoValorRepasse, setNovoValorRepasse] = useState("");

  const handleAtualizarRepasse = async () => {
    try {
      const novoValorRepasseFloat = parseFloat(novoValorRepasse / 100);
      console.log(
        "Novo valor de repasse float:",
        typeof parseFloat(novoValorRepasse)
      );
      const response = await api.put(`/medico/${prestador.id}/repasse`, {
        valor_de_repasse: novoValorRepasseFloat,
      });
      window.location.reload();
      console.log("Valor de repasse atualizado com sucesso!");
      toast.success("Valor de repasse atualizado com sucesso!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setMedico({
        ...medico,
        medico: { valor_repasse: parseFloat(novoValorRepasseFloat) },
      });
    } catch (error) {
      console.error("Erro ao atualizar o valor de repasse:", error);
      toast.error("Erro ao atualizar o valor de repasse", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <ToastContainer />
        <PageTitle>Prestador</PageTitle>
        <ProviderInfo>
          <ProviderRow>
            <InfoBlock>
              <InfoTitle>Nome:</InfoTitle>
              <Info>{prestador?.nome}</Info>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>CPF:</InfoTitle>
              <Info>{prestador?.medico?.cpf}</Info>
            </InfoBlock>
          </ProviderRow>
          <ProviderRow>
            <InfoBlock>
              <InfoTitle>Email:</InfoTitle>
              <Info>{prestador?.email}</Info>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Telefone:</InfoTitle>
              <Info>{prestador?.medico?.telefone}</Info>
            </InfoBlock>
          </ProviderRow>
          <ProviderRow>
            <InfoBlock>
              <InfoTitle>CRM:</InfoTitle>
              <Info>{prestador?.medico?.numero_de_registro_profissional}</Info>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Créditos:</InfoTitle>
              <Info>{prestador?.medico?.creditos} crédito(s)</Info>
            </InfoBlock>
          </ProviderRow>
          <ProviderRow>
            <InfoBlock>
              <InfoTitle>Valor da Consulta:</InfoTitle>
              <Info>{prestador?.medico?.creditos_consulta}</Info>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Chave Pix:</InfoTitle>
              <Info>{prestador?.dados_bancarios?.chave_pix}</Info>
            </InfoBlock>
          </ProviderRow>
          <ProviderRow>
            <InfoBlock>
              <InfoTitle>Especialidades:</InfoTitle>
              <Info>
                {prestador?.medico?.especialidades.map(
                  (item) => item.nome + ", "
                )}
              </Info>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>
                Porcentagem do repasse:{" "}
                {parseInt(prestador?.medico?.valor_de_repasse.toFixed(2) * 100)}
                %
              </InfoTitle>
              <InputPrestador
                type="number"
                placeholder="Insira o valor"
                value={novoValorRepasse}
                onChange={(e) => {
                  const newValue = Math.min(100, Math.max(0, e.target.value));
                  setNovoValorRepasse(newValue);
                }}
                min="0"
                max="100"
              />
              <ButtonPrestador onClick={handleAtualizarRepasse}>
                Atualizar Repasse
              </ButtonPrestador>
            </InfoBlock>
          </ProviderRow>
          <ProviderRow>
            <InfoBlock>
              <InfoTitle>Identidade:</InfoTitle>
              {/* <a href={prestador?.image}> */}
              <ImageContainer src={prestador?.image} alt=""></ImageContainer>
              {/* </a> */}
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Foto de Perfil:</InfoTitle>
              <ImageContainer
                src={prestador?.medico?.profile_image}
                alt=""
              ></ImageContainer>
            </InfoBlock>
          </ProviderRow>
          <ProviderRow>{buttons}</ProviderRow>
        </ProviderInfo>
      </PageContainer>
    </Page>
  );
};

export default InfosMedico;
