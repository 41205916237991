import React from "react";

import { OptionBtn } from "./styles";

const MainButton = ({ children, onClick, bgcolor }) => {
  return (
    <OptionBtn onClick={onClick} bgcolor={bgcolor}>
      {" "}
      {children}{" "}
    </OptionBtn>
  );
};

export default MainButton;
