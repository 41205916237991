import React, { useEffect, useState } from "react";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { MenuItem, PageTitle, Option, Menu, OptionTitle } from "./styles";
import PageContainer from "../../../Components/PageContainer";
import Chart from "react-apexcharts";
import { PageOptions } from "./styles";
import api from "../../../Services/api";
import MenuMetricas from "../../../Components/MenuMetricas";
import { optionsDependentes, optionsTitulares } from "./data";

export default function MetricasUsers() {
	const [fem, setFem] = useState();
	const [masc, setMasc] = useState();
	const [idadeTitular, setIdadeTitular] = useState([0, 0, 0, 0, 0]);
	const [idadeAfiliado, setIdadeAfiliado] = useState([0, 0, 0, 0, 0]);

	const br = require("apexcharts/dist/locales/pt-br.json");

	useEffect(() => {
		const getGender = async () => {
			try {
				const response = await api.get("/pacientes/sexos");
				const generos = response.data;
				setFem(generos.F);
				setMasc(generos.M);
			} catch (error) {
				console.log(error);
			}
		};
		const getTitularAges = async () => {
			try {
				const response = await api.get("/pacientes/idades");
				const titular_idade = response.data;
				setIdadeTitular(titular_idade);
			} catch (error) {
				console.log(error);
			}
		};
		const getAfiliateAges = async () => {
			try {
				const response = await api.get("/afiliado/idades");
				const afiliado_idade = response.data;
				setIdadeAfiliado(afiliado_idade);
			} catch (error) {
				console.log(error);
			}
		};
		getGender();
		getTitularAges();
		getAfiliateAges();
	}, []);

	const optionsGenero = {
		chart: {
			locales: [br],
			defaultLocale: "br",
		},
		colors: ["#663780", "#49B69B"],
		options: {
			legend: {
				offsetY: 20,
				offsetX: 10,
				markers: { fillColors: ["#663780", "#49B69B"] },
				labels: {
					colors: ["#4D4D4D"],
					useSeriesColors: false,
				},
			},
			chart: {
				zoom: {
					enabled: false,
				},
				fontFamily: "'Lexend'",
				foreColor: "#535353",
			},
			dataLabels: {
				enabled: false,
			},

			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: "bottom",
						},
					},
				},
			],
			fill: { colors: ["#663780", "#49B69B"] },
			stroke: {
				show: false,
				width: 2,
				dashArray: 0,
				colors: ["#663780", "#49B69B"],
			},
			title: {
				text: "Gênero Titulares",
				align: "center",
				offsetX: 0,

				style: {
					color: "#929292",
					fontStyle: "normal",
					fontWeight: 600,
					fontSize: "16px",
					lineHeight: "20px",
				},
			},
			labels: [`Feminino %`, `Masculino %`],
		},
	};

	const seriesTitular = {
		"Até 5 anos %": idadeTitular["0-5"],
		"5 a 18 anos %": idadeTitular["6-18"],
		"19 a 35 anos %": idadeTitular["19-35"],
		"35 a 60 anos %": idadeTitular["36-60"],
		"Maior de 60 anos %": idadeTitular["60+"],
	};

	const seriesAfiliado = {
		"Até 5 anos %": idadeAfiliado["0-5"],
		"5 a 18 anos %": idadeAfiliado["6-18"],
		"19 a 35 anos %": idadeAfiliado["19-35"],
		"35 a 60 anos %": idadeAfiliado["36-60"],
		"Maior de 60 anos %": idadeAfiliado["60+"],
	};

	const reduce = (obj) => {
		return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== 0));
	};

	const optionsTitulares = {
		series: Object.values(reduce(seriesTitular)).map((value, key) => {
			return value;
		}),
		chart: {
			locales: [br],
			defaultLocale: "br",
		},
		colors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"],
		options: {
			legend: {
				offsetY: 20,
				markers: { fillColors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"] },
				labels: {
					colors: ["#4D4D4D"],
					useSeriesColors: false,
				},
			},
			chart: {
				zoom: {
					enabled: false,
				},
				fontFamily: "'Lexend'",
				foreColor: "#535353",
			},
			dataLabels: {
				enabled: false,
			},

			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: "bottom",
						},
					},
				},
			],
			fill: { colors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"] },
			stroke: {
				show: false,
				width: 2,
				dashArray: 0,
				colors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"],
			},
			title: {
				text: "Titulares",
				align: "left",
				offsetX: 0,

				style: {
					color: "#929292",
					fontStyle: "normal",
					fontWeight: 600,
					fontSize: "16px",
					lineHeight: "20px",
				},
			},
			labels: Object.keys(reduce(seriesTitular)).map((value) => {
				return value;
			}),
		},
	};

	const optionsDependentes = {
		series: Object.values(reduce(idadeAfiliado)).map((value, key) => {
			return value;
		}),
		chart: {
			locales: [br],
			defaultLocale: "br",
		},
		colors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"],
		options: {
			legend: {
				offsetY: 20,
				markers: { fillColors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"] },
				labels: {
					colors: ["#4D4D4D"],
					useSeriesColors: false,
				},
			},
			chart: {
				zoom: {
					enabled: false,
				},
				fontFamily: "'Lexend'",
				foreColor: "#535353",
			},
			dataLabels: {
				enabled: false,
			},

			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: "bottom",
						},
					},
				},
			],
			fill: { colors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"] },
			stroke: {
				show: false,
				width: 2,
				dashArray: 0,
				colors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"],
			},
			title: {
				text: "Dependentes",
				align: "left",
				offsetX: 0,

				style: {
					color: "#929292",
					fontStyle: "normal",
					fontWeight: 600,
					fontSize: "16px",
					lineHeight: "20px",
				},
			},
			labels: Object.keys(reduce(seriesAfiliado)).map((value) => {
				return value;
			}),
		},
	};

	return (
		<Page>
			<SidebarMenu />
			<PageContainer>
				<PageTitle>Métricas</PageTitle>
				<PageOptions>
					<MenuMetricas></MenuMetricas>
					<Option>
						<OptionTitle>Gênero</OptionTitle>
						<div className="flex justify-center items-center h-fit py-8 w-4/5 bg-brancoID rounded-[20px]">
							<Chart
								options={optionsGenero.options}
								series={[fem, masc]}
								type="donut"
								height={250}
								width={550}
							></Chart>
						</div>
					</Option>
					<Option>
						<OptionTitle>Distribuição Etária</OptionTitle>
						<div className="flex justify-center items-center h-fit py-8 w-4/5 bg-brancoID rounded-[20px]">
							<Chart
								options={optionsTitulares.options}
								series={optionsTitulares.series}
								type="donut"
								height={250}
								width={550}
							></Chart>
						</div>
						<div className="flex justify-center items-center h-fit py-8 w-4/5 bg-brancoID rounded-[20px]">
							<Chart
								options={optionsDependentes.options}
								series={optionsDependentes.series}
								type="donut"
								height={250}
								width={550}
							></Chart>
						</div>
					</Option>
				</PageOptions>
			</PageContainer>
		</Page>
	);
}
