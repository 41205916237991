import styled from "styled-components";

export const InfoBlock = styled.p`
  display: flex;
  flex-direction: column;
  gap: 1vh;
  width: 25vw;
`;

export const InfoTitle = styled.h3`
  color: ${(props) => props.theme.colors.roxo};
`;

export const Info = styled.p`
  font-size: large;
`;

export const RecusarButton = styled.div`
  background-color: red;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-family:'Lexend','sans-serif';
`;
