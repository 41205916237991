import React from "react";
import { Page } from "../../SignedOut/Cadastro/styles";
import SidebarMenu from "../../../Components/SidebarMenu";
import {
  PageTitle,
  InputDescription,
  InputAndButtonContainer,
  InputCPF,
  SearchButton,
} from "../Pagamento/styles";
import PageContainer from "../../../Components/PageContainer";

function PagamentoFeito() {
  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Repasse do Pagamento</PageTitle>
        <InputDescription>
          Digite o CPF do médico que você deseja fazer o repasse do pagamento
        </InputDescription>
        <InputAndButtonContainer>
          <InputCPF
            placeholder="Digite o CPF"
            mask="999.999.999-99"
            maskChar={null}
            //value={cpf}
            //onChange={(event) => setCPF(event.target.value)}
          ></InputCPF>

          <SearchButton /*onClick={handleSearch}*/>Pesquisar</SearchButton>
        </InputAndButtonContainer>
      </PageContainer>
    </Page>
  );
}

export default PagamentoFeito;
