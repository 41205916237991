import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const SidebarMenuContainer = styled.div`
	height: 100vh;
	width: 18vw;
	min-width: 200px;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	overflow-x: hidden;
	//background-color: #EFCA04;
	background: ${(props) => props.theme.colors.roxo};
	display: flex;
	align-items: center;
	flex-direction: column;
`;

export const Logo = styled.img`
	padding-top: 12%;
	height: 10vh;
`;

export const Menu = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 2vh;
`;

export const MenuItem = styled.li`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	list-style: none;
	a {
		text-decoration: none;
		color: ${(props) => props.theme.colors.brancoID};
		font-size: 20px;
		padding: 0 20px;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		border-radius: 5px;
		&:hover {
			cursor: pointer;
			background-color: rgba(6, 113, 135, 0.25);
			color: ${(props) => props.theme.colors.brancoID};
		}
	}
`;

export const MenuLink = styled(NavLink)`
	background: none;
`;

export const ItemText = styled.span`
	margin-left: 15px;
`;
