import styled from "styled-components";

export const PacientInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const InfoBlock = styled.p`
  display: flex;
  flex-direction: column;
`;

export const InfoTitle = styled.h3`
  color: ${(props) => props.theme.colors.verdeClaro};
`;

export const Info = styled.p`
  font-size: large;
  max-width: 400px;
`;

export const Content = styled.div`
  display: flex;
  gap: 70px;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  gap: 7vh;
  align-items: center;
  margin-left: 20vw;
  padding-bottom: 5%;
`;

export const PageTitle = styled.h1`
  font-weight: bold;
  color: ${(props) => props.theme.colors.verdeEscuro};
  border-bottom: 2px solid ${(props) => props.theme.colors.pretoID};
  padding-top: 3%;
`;

export const Status = styled.div`
  display: flex;
  padding: 5px 10px;
  background-color: red;
  color: white;
  font-size: large;

  border-radius: 5px;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.roxo};
`;
