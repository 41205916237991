import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SidebarMenu from "../../../Components/SidebarMenu";
import api from "../../../Services/api";
import { Page } from "../../SignedOut/Cadastro/styles";
import { PageTitle, RegisterInput } from "../CadastrarMedico/styles";
import {
  Option,
  OptionBtn,
  OptionTitle,
  PageOptions,
} from "../Creditos/styles";
import { ToastContainer, toast } from "react-toastify";
import { Boxcentral } from "../ValorCredito/styles";
import "react-toastify/dist/ReactToastify.css";
import PageContainer from "../../../Components/PageContainer";

export default function RepassePagamento() {
  const history = useHistory();
  const [valorAtual, setValorAtual] = useState(0);
  const [novoValor, setNovoValor] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get("/creditos");
        const dados = response.data;
        const valorAdmin = dados.margem_admin;
        console.log(valorAdmin);
        console.log("response", response.data);
        setValorAtual(valorAdmin);
      } catch (error) {
        console.log(error.response.data);
        console.log("PQP deu errooo");
      }
    };
    getData();
  }, []);

  const handleConfirmChanges = async () => {
    try {
      const novoValorCalculado = parseFloat(novoValor) / 100;
      const response = await api.patch("/creditos", {
        margem_admin: novoValorCalculado,
      });
      setValorAtual(novoValorCalculado);
      setNovoValor(0);
      toast.success("Alterações confirmadas com sucesso!");
    } catch (error) {
      console.log(error.response.data);
      toast.error("Erro ao confirmar as alterações.");
    }
  };

  const handleInputChange = (e) => {
    let newValue = parseFloat(e.target.value);
    if (!newValue) {
      newValue = 0;
    }
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > 100) {
      newValue = 100;
    }
    setNovoValor(newValue);
  };

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Repasse do pagamento</PageTitle>
        <PageOptions>
          <Option>
            <OptionTitle>Digite o valor que você deseja (%): </OptionTitle>
            <RegisterInput
              placeholder="Digite o Valor"
              value={novoValor}
              onChange={handleInputChange}
            />
          </Option>
          <Option>
            <OptionTitle>Valor do repasse é {valorAtual * 100}%</OptionTitle>
          </Option>
          <Boxcentral>
            <OptionBtn onClick={handleConfirmChanges}>
              Confirmar Mudanças
            </OptionBtn>
          </Boxcentral>
        </PageOptions>
      </PageContainer>
    </Page>
  );
}
