import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        text-decoration: none;
        outline: 0;
        border: none;
    }
    body{
        font-family: "Lexend", sans-serif;
    }
`;