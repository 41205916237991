import React, { useEffect, useState } from 'react'
import { InputContainer, Page, PageContainer, PageTitle } from '../Cadastro/styles'
import Sidebar from '../../../Components/Sidebar'
import { EmailInput, PasswordInfo, PasswordResetContainer, SendButton } from '../EsqueciMinhaSenha/styles'
import ReactLoading from 'react-loading';
import { NavLink, useHistory } from 'react-router-dom';
import api from '../../../Services/api';
import { RedirectButton, RedirectContainer } from '../Login/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CodigoEmail(props) {
      const [invalidFields, setInvalidFields] = useState(false);
      const history = useHistory();
      const [codigo, setCodigo] = useState('');

      function handleSubmit(){
            const ValidaCodigo = async () => {
            try {
                  const data = {
                        email: props.location.state,
                        pin: codigo,
                  }
                  console.log(data);
                  const response = await api.post("/verificar-pin", data);
                  const token = response.data["reset-token"]
                  history.push({ 
                        pathname: '/redefinir-senha',
                        state: token
                       });
                       

            } catch (error) {
                  console.log(error);
                  const showToastMessage = () => {
                        toast.error(`Código não é válido`, {
                            position: toast.POSITION.TOP_CENTER
                        });
                      }
                        showToastMessage()
            }
      }
      ValidaCodigo();
      }

      useEffect(()=>{
            if(!codigo){
                  setInvalidFields(true);
            } else {
                  setInvalidFields(false);
            }
      },[codigo])

  return (
      <Page>
      <Sidebar/>
      <PageContainer>
          <PasswordResetContainer>
              <PageTitle>Esqueci minha senha</PageTitle>
              <PasswordInfo>
                  Digite o codigo de recuperação de senha que foi enviado para o seu email
              </PasswordInfo>
              <InputContainer>
                        <EmailInput
                            placeholder='Digite o codigo'
                            type='text'
                            value={codigo}
                            onChange={(event)=> setCodigo(event.target.value)}
                        />
                        <SendButton onClick={handleSubmit} disabled={invalidFields}> 
                              {false ? 
                                    <ReactLoading type="spin" color="#B2EBF2" height={"30px"} width={"30px"}/> :"Enviar"}
                        </SendButton>
              </InputContainer>
              <RedirectContainer>
                        <NavLink to='/'> <RedirectButton>Voltar à tela de login</RedirectButton> </NavLink>
                    </RedirectContainer>
            </PasswordResetContainer>
      </PageContainer>
      </Page>
  )
}

export default CodigoEmail