import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";

export const TableContainer = styled.div`
    display: flex;
    width: 70vw;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 1em ${(props) => props.theme.colors.darkBlue};
    border-top: 1px solid ${props => props.theme.colors.mediumBlue};
    border-left: 1px solid ${props => props.theme.colors.mediumBlue};
    border-right: 1px solid ${props => props.theme.colors.mediumBlue};
    margin-bottom: 5%;
`;

export const TableList = styled.div`
`;

export const ErrorMsg = styled.p`
    padding-top: 10%;
    font-size: large;
    color: ${(props) => props.theme.colors.red};
`;

export const ErrorBtn = styled.button`
    width: 15vw;
    height: 8vh;
    font-size: medium;
    cursor: pointer;
    align-items: center;
    border-radius: 20px;
    color: white;
    background-color: ${(props) => props.theme.colors.darkBlue};
`;

export const DataList = styled.div`
    width: 65vw;
`;

export const MyDataGrid = styled(DataGrid)`
    width: 80%;
    height: 100%;
    border-radius: 20px;
`;