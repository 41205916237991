import styled from "styled-components";

export const OptionBtn = styled.button`
  cursor: pointer;
  font-size: medium;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : props.theme.colors.verdeClaro};
  color: ${(props) => props.theme.colors.brancoID};
  font-family: 'Lexend', 'sans';
  font-weight: bold;
`;
