import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import grafismo from '../../../Assets/grafismo.png';

export const Page = styled.div`
  display: flex;
  flex-direction: row;
  background-image: url('${grafismo}');
  background-repeat: repeat;
  background-size: 55%;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 7vh;
  align-items: center;
  margin-left: 20vw;
  padding-bottom: 5%;
  
`;

export const PageTitle = styled.h1`
  font-weight: bold;
  color: ${(props) => props.theme.colors.verdeEscuro};
  border-bottom: 2px solid ${(props) => props.theme.colors.pretoID};
  padding-top: 3%;
`;

export const ErrorMsg = styled.p`
  padding-top: 10%;
  font-size: large;
  color: ${(props) => props.theme.colors.red};
`;

export const ErrorBtn = styled.button`
  width: 15vw;
  height: 8vh;
  font-size: medium;
  cursor: pointer;
  align-items: center;
  border-radius: 20px;
  color: white;
  background-color: ${(props) => props.theme.colors.darkBlue};
`;

export const DataList = styled.div`
  width: 65vw;
`;

export const MyDataGrid = styled(DataGrid)`
  width: 80%;
  height: 100%;
  border-radius: 20px;
`;

export const Status = styled.div`
  display: flex;
  padding: 5px 10px;
  background-color: ${(props) => props.status};
  color: white;
  font-size: large;
  border-radius: 5px;
`;
