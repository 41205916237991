import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SidebarMenu from "../../../Components/SidebarMenu";
import api from "../../../Services/api";
import { Page } from "../../SignedOut/Cadastro/styles";
import { PageTitle } from "../CadastrarMedico/styles";
import ReactLoading from "react-loading";
import { useHistory } from "react-router";
import PageContainer from "../../../Components/PageContainer";
import InfosMedico from "../../../Components/InfosMedico";
import InfosVendedor from "../../../Components/InfosVendedor";
import { Info } from "../PacienteUnico/styles";
import { toast } from "react-toastify";
import BlockButton from "../../../Components/BlockButton";
import MainButton from "../../../Components/MainButton";
import { ButtonContainer } from "./styles";

export default function PendenteUnico() {
  const { id } = useParams();
  const [prestador, setPrestador] = useState([]);
  const [loading, setLoading] = useState(true);
  const [requestDone, setRequestDone] = useState(false);

  const history = useHistory();

  async function handleStatusChange(id, novoStatus) {
    try {
      await api.patch(`/user/${id}/status`, { status: novoStatus });
      console.log(handleStatusChange);
      const showToastMessage = () => {
        toast.success(`Sucesso ao alterar status do prestador `, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessage();
      history.goBack()
    } catch (error) {
      const showToastMessageError = () => {
        toast.error(`Não foi possivel completar a operação`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessageError();
      console.log(error);
    }
  }

  useEffect(() => {
    const fetchRepositoriesData = async () => {
      setLoading(true);

      try {
        const response = await api.get("/user/" + id);
        setPrestador(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setRequestDone(true);
      }
    };
    fetchRepositoriesData();
  }, [id]);

  if (loading || !requestDone) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Prestador</PageTitle>
          <ReactLoading
            type="spin"
            color="#B2EBF2"
            height={"30px"}
            width={"30px"}
          />
        </PageContainer>
      </Page>
    );
  }

  if (prestador?.tipo === "medico") {
    return (
      <InfosMedico
        prestador={prestador}
        buttons={
          <ButtonContainer>
            <MainButton
              onClick={() => handleStatusChange(prestador.id, "Active")}
              bgcolor="green"
            >
              Aceitar
            </MainButton>
            <MainButton
              onClick={() => handleStatusChange(prestador.id, "Suspended")}
              bgcolor="red"
            >
              Recusar
            </MainButton>
          </ButtonContainer>
        }
      />
    );
  } else {
    <PageContainer>
      <Info>Erro ao carregar prestador</Info>
    </PageContainer>;
  }
  // return <Info>fdihsfoi</Info>;
}
