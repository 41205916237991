import React, { useEffect } from "react";
import { SidebarContainer, Logo } from "./styles";
import SaudeLogo from "../../Assets/LogoNovi.png";
import IconLogo from "../../Assets/logo.png";
import { useHistory } from "react-router";
import { useAuth } from "../../Hooks/useAuth";

export default function Sidebar() {
	const history = useHistory();
	const { token } = useAuth();

	useEffect(() => {
		if (token) {
			history.push("/perfil");
		}
	}, [token, history]);

	return (
		<SidebarContainer>
			<Logo src={SaudeLogo} />
		</SidebarContainer>
	);
}
