import styled from 'styled-components';

export const SidebarContainer = styled.div`
    height: 100vh;
    width: 32vw;
    background-image: linear-gradient(#663780, #663780);
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export const Logo = styled.img`
    padding-top: 20vh;
    width: 60%;
`;