import styled from "styled-components";
import theme from "../../../Theme";
import InputMask from "react-input-mask";

import grafismo from "../../../Assets/grafismo.png";

// InputCPF,
// InputDescription,
// SearchButton,
// InfosContainer,
// InfosText,
// LoadingText,
// PaymentButton,

export const Background = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-image: url("${grafismo}"), url("${grafismo}");
	background-position: top -150px left -150px, bottom -150px right -150px;
	background-repeat: no-repeat, no-repeat;
	background-size: 55%;
`;

export const InputDescription = styled.p`
	font-size: 18px;
	font-weight: bold;
	color: ${theme.colors.roxo};
	text-align: center;
	width: 100%;
`;

export const InputAndButtonContainer = styled.div``;

export const InputCPF = styled(InputMask)`
	height: 30px;
	width: 200px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 0 10px;
	margin-right: 10px;
	font-size: 16px;
	font-weight: bold;
	outline: none;
	transition: all ease 0.2s;
	&:focus {
		border: 1px solid #000;
	}
`;

export const InputProcedimento = styled(InputMask)`
	height: 30px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 0 10px;
	margin-right: 10px;
	font-size: 16px;
	font-weight: bold;
	outline: none;
	transition: all ease 0.2s;
	&:focus {
		border: 1px solid #000;
	}
`;

export const SearchButton = styled.button`
	padding: 5px 10px;
	background-color: ${theme.colors.verdeEscuro};
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	border: none;
	border-radius: 5px;
	outline: none;
	cursor: pointer;
	transition: all ease 0.2s;
	&:hover {
		background-color: ${theme.colors.verdeClaro};
	}
`;

export const InfosContainer = styled.div`
	margin-top: 20px;
	width: 70%;
	min-width: 500px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	gap: 10px;
	background-color: ${theme.colors.roxo};
	border-radius: 10px;
`;

export const TokenContainer = styled.div`
	margin-top: 20px;
	width: 25%;
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 10px;
	background-color: ${theme.colors.roxo};
	border-radius: 10px;
`;

export const InfosText = styled.p`
	font-size: 16px;
	font-weight: bold;
	color: ${theme.colors.verdeClaro};
	display: flex;
	flex-direction: row;
	gap: 5px;
`;

export const TitleText = styled.p`
	font-size: 16px;
	font-weight: bold;
	color: ${theme.colors.brancoID};
`;

export const LoadingText = styled.p`
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: bold;
`;

export const PaymentButton = styled.button`
	padding: 5px 10px;
	background-color: ${theme.colors.verdeClaro};
	color: ${theme.colors.brancoID};
	font-size: 16px;
	font-weight: bold;
	border: none;
	border-radius: 5px;
	outline: none;
	cursor: pointer;
	transition: all ease 0.2s;
	&:hover {
		background-color: ${theme.colors.verdeEscuro};
	}
`;

export const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 7vh;
	align-items: center;
	margin-left: 20vw;
`;

export const PageTitle = styled.h1`
	font-weight: bold;
	color: ${(props) => props.theme.colors.verdeEscuro};
	border-bottom: 2px solid ${(props) => props.theme.colors.preto};
	padding-top: 3%;
`;

export const Left = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 10px;
	padding: 20px;
`;

export const Right = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 10px;
	padding: 20px;
`;
