const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const downloadsInfo = [
	{
		title: "Total",
		value: 2.801,
		icon: (
			<svg
				width="56"
				height="56"
				viewBox="0 0 56 56"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M48.2568 38.666V48.9731H7.02818V38.666H0.156738V48.9731C0.156738 52.7524 3.24888 55.8445 7.02818 55.8445H48.2568C52.0361 55.8445 55.1282 52.7524 55.1282 48.9731V38.666H48.2568ZM44.8211 24.9231L39.9767 20.0787L31.0782 28.9429V0.873047H24.2068V28.9429L15.3083 20.0787L10.4639 24.9231L27.6425 42.1017L44.8211 24.9231Z"
					fill="#4D4D4D"
				/>
			</svg>
		),
	},
	{
		title: "Android",
		value: 1.234,
		icon: (
			<svg
				width="50"
				height="55"
				viewBox="0 0 50 55"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M9.92716 5.60542C14.2435 2.14706 19.6116 0.266602 25.1426 0.275422C30.8978 0.275422 36.1872 2.26875 40.358 5.60542L44.2932 1.67021L48.1228 5.4998L44.1876 9.435C47.6459 13.7514 49.5264 19.1195 49.5176 24.6504V27.3588H0.767578V24.6504C0.767578 18.8952 2.76091 13.6058 6.09758 9.435L2.16237 5.5025L5.99195 1.67292L9.92716 5.60813V5.60542ZM0.767578 32.7754H49.5176V51.7338C49.5176 52.452 49.2322 53.1409 48.7243 53.6488C48.2164 54.1567 47.5275 54.4421 46.8092 54.4421H3.47591C2.75762 54.4421 2.06874 54.1567 1.56083 53.6488C1.05292 53.1409 0.767578 52.452 0.767578 51.7338V32.7754ZM17.0176 19.2338C17.7359 19.2338 18.4247 18.9484 18.9327 18.4405C19.4406 17.9326 19.7259 17.2437 19.7259 16.5254C19.7259 15.8071 19.4406 15.1183 18.9327 14.6103C18.4247 14.1024 17.7359 13.8171 17.0176 13.8171C16.2993 13.8171 15.6104 14.1024 15.1025 14.6103C14.5946 15.1183 14.3092 15.8071 14.3092 16.5254C14.3092 17.2437 14.5946 17.9326 15.1025 18.4405C15.6104 18.9484 16.2993 19.2338 17.0176 19.2338ZM33.2676 19.2338C33.9859 19.2338 34.6747 18.9484 35.1827 18.4405C35.6906 17.9326 35.9759 17.2437 35.9759 16.5254C35.9759 15.8071 35.6906 15.1183 35.1827 14.6103C34.6747 14.1024 33.9859 13.8171 33.2676 13.8171C32.5493 13.8171 31.8604 14.1024 31.3525 14.6103C30.8446 15.1183 30.5592 15.8071 30.5592 16.5254C30.5592 17.2437 30.8446 17.9326 31.3525 18.4405C31.8604 18.9484 32.5493 19.2338 33.2676 19.2338Z"
					fill="#4D4D4D"
				/>
			</svg>
		),
	},
	{
		title: "iOS",
		value: 1.567,
		icon: (
			<svg
				width="50"
				height="61"
				viewBox="0 0 50 61"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M25.6243 17.4196C23.2518 17.4196 19.5793 14.7221 15.7119 14.8196C10.6094 14.8846 5.92935 17.7771 3.29685 22.3596C-2.00065 31.5571 1.93185 45.1421 7.09935 52.6171C9.63435 56.2571 12.6244 60.3521 16.5893 60.2221C20.3918 60.0596 21.8218 57.7521 26.4368 57.7521C31.0193 57.7521 32.3193 60.2221 36.3493 60.1246C40.4443 60.0596 43.0443 56.4196 45.5468 52.7471C48.4393 48.5221 49.6418 44.4271 49.7068 44.1996C49.6093 44.1671 41.7444 41.1446 41.6469 32.0446C41.5819 24.4396 47.8543 20.7996 48.1469 20.6371C44.5718 15.4046 39.0793 14.8196 37.1618 14.6896C32.1568 14.2996 27.9643 17.4196 25.6243 17.4196ZM34.0743 9.74961C36.1868 7.21461 37.5843 3.67211 37.1943 0.162109C34.1718 0.292109 30.5318 2.17711 28.3543 4.71211C26.4043 6.95461 24.7143 10.5621 25.1693 14.0071C28.5168 14.2671 31.9618 12.2846 34.0743 9.74961Z"
					fill="#4D4D4D"
				/>
			</svg>
		),
	},
];

export const prestadoresInfo = [
	{
		title: "Total",
		value: 2.801,
		icon: (
			<svg
				width="66"
				height="34"
				viewBox="0 0 66 34"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M33.1426 18.8906C37.5572 18.8906 41.4572 19.9469 44.6259 21.3281C47.5509 22.6281 49.3926 25.5531 49.3926 28.7219V33.1094H16.8926V28.749C16.8926 25.5531 18.7342 22.6281 21.6592 21.3552C24.828 19.9469 28.728 18.8906 33.1426 18.8906ZM11.4759 19.5677C14.4551 19.5677 16.8926 17.1302 16.8926 14.151C16.8926 11.1719 14.4551 8.73437 11.4759 8.73437C8.49674 8.73437 6.05924 11.1719 6.05924 14.151C6.05924 17.1302 8.49674 19.5677 11.4759 19.5677ZM14.5363 22.5469C13.5342 22.3844 12.5322 22.276 11.4759 22.276C8.79466 22.276 6.24883 22.8448 3.94674 23.8469C1.94258 24.7135 0.642578 26.6635 0.642578 28.8573V33.1094H12.8301V28.749C12.8301 26.501 13.453 24.3885 14.5363 22.5469ZM54.8092 19.5677C57.7884 19.5677 60.2259 17.1302 60.2259 14.151C60.2259 11.1719 57.7884 8.73437 54.8092 8.73437C51.8301 8.73437 49.3926 11.1719 49.3926 14.151C49.3926 17.1302 51.8301 19.5677 54.8092 19.5677ZM65.6426 28.8573C65.6426 26.6635 64.3426 24.7135 62.3384 23.8469C60.0363 22.8448 57.4905 22.276 54.8092 22.276C53.753 22.276 52.7509 22.3844 51.7488 22.5469C52.8322 24.3885 53.4551 26.501 53.4551 28.749V33.1094H65.6426V28.8573ZM33.1426 0.609375C37.6384 0.609375 41.2676 4.23854 41.2676 8.73437C41.2676 13.2302 37.6384 16.8594 33.1426 16.8594C28.6467 16.8594 25.0176 13.2302 25.0176 8.73437C25.0176 4.23854 28.6467 0.609375 33.1426 0.609375Z"
					fill="#4D4D4D"
				/>
			</svg>
		),
	},
];

const br = require("apexcharts/dist/locales/pt-br.json");

const dataAcessosMes = [0, 160, 240, 350, 700, 880, 949, 1050, 1234, 1300];

export const optionsAcessosMes = {
	series: [
		{
			name: "Acessos",
			data: dataAcessosMes,
		},
	],
	chart: {
		locales: [br],
		defaultLocale: "br",
	},
	options: {
		colors: ["#663780"],
		chart: {
			height: 350,
			type: "line",
			zoom: {
				enabled: false,
			},
			fontFamily: "'Lexend'",
			foreColor: "#535353",
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "straight",
			width: 3,
		},
		title: {
			text: "Número de acessos por mês",
			align: "left",
			style: {
				color: "#1E1E24",
				fontStyle: "normal",
				fontWeight: 400,
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		grid: {
			row: {
				colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
				opacity: 0.5,
			},
		},
		xaxis: {
			categories: [
				"Jan",
				"Fev",
				"Mar",
				"Abr",
				"Mai",
				"Jun",
				"Jul",
				"Ago",
				"Set",
				"Out",
				"Nov",
				"Dez",
			],
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "12px",
					lineHeight: "15px",
					textAlign: "center",
				},
			},
		},
		yaxis: {
			show: true,
			forceNiceScale: true,
			stepSize: 300,
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "11px",
					lineHeight: "14px",
					textAlign: "right",
				},
			},
		},
	},
};

const dataDownloadsAndroid = [5, 100, 140, 150, 350, 500, 449, 550, 700, 750];
const dataDownloadsIos = [20, 60, 100, 200, 350, 380, 500, 500, 534, 550];
const dataDownloadsTotal = dataDownloadsAndroid.map(function (num, idx) {
	return num + dataDownloadsIos[idx];
});

export const optionsDownloadsMes = {
	series: [
		{
			name: "Total",
			data: dataDownloadsTotal,
		},
		{
			name: "Android",
			data: dataDownloadsAndroid,
		},
		{
			name: "iOS",
			data: dataDownloadsIos,
		},
	],
	options: {
		colors: ["#663780", "#49B69B", "#EFCA04"],
		chart: {
			height: 350,
			type: "line",
			zoom: {
				enabled: false,
			},
			fontFamily: "'Lexend'",
			foreColor: "#535353",
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "straight",
			width: 3,
		},
		title: {
			text: "Número de downloads por mês",
			align: "left",
			style: {
				color: "#1E1E24",
				fontStyle: "normal",
				fontWeight: 400,
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		grid: {
			row: {
				colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
				opacity: 0.5,
			},
		},
		xaxis: {
			categories: [
				"Jan",
				"Fev",
				"Mar",
				"Abr",
				"Mai",
				"Jun",
				"Jul",
				"Ago",
				"Set",
				"Out",
				"Nov",
				"Dez",
			],
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "12px",
					lineHeight: "15px",
					textAlign: "center",
				},
			},
		},
		yaxis: {
			show: true,
			forceNiceScale: true,
			stepSize: 300,
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "11px",
					lineHeight: "14px",
					textAlign: "right",
				},
			},
		},
		legend: {
			position: "top",
			horizontalAlign: "right",
			floating: true,
			offsetY: -25,
			offsetX: 50,
			markers: {
				size: 15,
				shape: "line", // circle, square, line, plus, cross
				strokeWidth: 2,
			},
		},
	},
};

const dataTitulares = [25, 160, 240, 350, 700, 880, 949, 1050, 1234, 1300];
const dataMediaDependentes = [5, 100, 140, 150, 350, 500, 449, 550, 700, 750];
const dataDependentesCadastrados = [20, 60, 100, 200, 350, 380, 500, 500, 534, 550];

export const optionsPacientes = {
	series: [
		{
			name: "Titulares cadastrados",
			data: dataTitulares,
		},
		{
			name: "Média dependentes",
			data: dataMediaDependentes,
		},
		{
			name: "Dependentes cadastrados",
			data: dataDependentesCadastrados,
		},
	],
	options: {
		colors: ["#663780", "#49B69B", "#EFCA04"],
		chart: {
			height: 350,
			type: "line",
			zoom: {
				enabled: false,
			},
			fontFamily: "'Lexend'",
			foreColor: "#535353",
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "straight",
			width: 3,
		},
		title: {
			text: "Número de titulares cadastrados",
			align: "left",
			style: {
				color: "#1E1E24",
				fontStyle: "normal",
				fontWeight: 400,
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		grid: {
			row: {
				colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
				opacity: 0.5,
			},
		},
		xaxis: {
			categories: [
				"Jan",
				"Fev",
				"Mar",
				"Abr",
				"Mai",
				"Jun",
				"Jul",
				"Ago",
				"Set",
				"Out",
				"Nov",
				"Dez",
			],
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "12px",
					lineHeight: "15px",
					textAlign: "center",
				},
			},
		},
		yaxis: {
			show: true,
			forceNiceScale: true,
			stepSize: 300,
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "11px",
					lineHeight: "14px",
					textAlign: "right",
				},
			},
		},
		legend: {
			position: "top",
			horizontalAlign: "right",
			floating: true,
			offsetY: -25,
			offsetX: 50,
			markers: {
				size: 15,
				shape: "line", // circle, square, line, plus, cross
				strokeWidth: 2,
			},
		},
	},
};

export const optionsPrestadores = {
	options: {
		colors: ["#663780"],
		chart: {
			height: 350,
			type: "line",
			zoom: {
				enabled: false,
			},
			fontFamily: "'Lexend'",
			foreColor: "#535353",
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "straight",
			width: 3,
		},
		title: {
			text: "Número de prestadores cadastrados",
			align: "left",
			style: {
				color: "#1E1E24",
				fontStyle: "normal",
				fontWeight: 400,
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		grid: {
			row: {
				colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
				opacity: 0.5,
			},
		},
		xaxis: {
			type: "category",
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "12px",
					lineHeight: "15px",
					textAlign: "center",
				},
			},
		},
		yaxis: {
			show: true,
			forceNiceScale: true,
			stepSize: 300,
			labels: {
				style: {
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "11px",
					lineHeight: "14px",
					textAlign: "right",
				},
			},
		},
	},
};
