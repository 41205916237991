import CarrosselLP from "../CarrosselLP";
import ContainerPrestadorLP from "../ContainerPrestadorLP";
import '../CarrosselLP/CarrosselLP.css';
import InputLP from "../InputLP";
import ContainerMedicosLP from "../ContainerMedicosLP";
import {useState} from 'react';

const MainLP = () => {
    const [busca, setBusca] = useState('');
    return (
        <div className="flex flex-col ">
            <CarrosselLP/>
            <div id="div2" className=" bg-brancoID flex flex-col  items-center justify-center">
                <ContainerPrestadorLP/>
                <InputLP  setBusca={setBusca} busca={busca}/>
                
            </div>
            <ContainerMedicosLP busca={busca}/>
        </div>
    )
}

export default MainLP;