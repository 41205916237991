import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const PageOptions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4vh;
`;

export const PageTitle = styled.h1`
	font-weight: bold;
	color: ${(props) => props.theme.colors.verdeEscuro};
	border-bottom: 2px solid ${(props) => props.theme.colors.pretoID};
	padding-top: 3%;
	font-family: "Cairo", sans-serif;
`;

export const Option = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 60vw;
	gap: 7vh;
	padding-bottom: 4vh;
`;

export const OptionTitle = styled.h2`
	color: ${(props) => props.theme.colors.roxo};
	font-weight: normal;
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 30px;
	width: 60vw;
	color: #663780;
`;

export const OptionBtn = styled.button`
	cursor: pointer;
	height: max-content;
	width: max-content;
	font-size: medium;
	padding: 1.5%;
	margin-left: 8vh;
	border-radius: 10px;
	background-color: ${(props) => props.theme.colors.roxo};
	color: ${(props) => props.theme.colors.brancoID};
	font-family: "Lexend", sans-serif;
`;

export const CreditsLine = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const CreditsText = styled.p`
	font-size: large;
	padding: 1.5%;
	margin-left: 8vh;
`;

export const MenuItem = styled.li`
	color: #663780;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 55px;
	min-width: 108px;

	background-color: white;
	a {
		text-decoration: none;
		font-size: 20px;
		padding: 0 20px;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		border: 2px solid #663780;
		border-radius: 5px;
		color: #663780;
		background-color: white;
		&:hover {
			cursor: pointer;
			background-color: #663780;
			color: #f3f3f3;
		}
	}
`;

export const MenuLink = styled(NavLink)`
	background: none;
	background-color: none;
`;

export const ItemText = styled.span`
	color: #663780;
`;
