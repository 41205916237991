import React, { useEffect, useState } from "react";
import api from "../../../Services/api";
import { useHistory } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { PageTitle } from "../CadastrarMedico/styles";
import { ErrorMsg, ErrorBtn, DataList, MyDataGrid } from "../Pacientes/styles";
import { BsEyeFill, BsArrowRight, BsArrowLeft } from "react-icons/bs";
import ReactLoading from "react-loading";
import { AceitarButton, RecusarButton } from "./styled";
import { toast } from "react-toastify";
import PageContainer from "../../../Components/PageContainer";
import Tabela from "../../../Components/Tabela";
import { Background, PageContainerDiv, RightDiv } from "../Perfil/styles";
import DataTable from 'react-data-table-component';
import { CgExport } from "react-icons/cg";

export default function Pendentes() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorRepositories, setErrorRepositories] = useState("");
  const [repositoriesData, setRepositoriesData] = useState([]);
  const [infoPagina, setInfoPagina] = useState(
    {
      has_next: false,
      has_previous: false,
      per_page: 0,
      page: 1,
      total: 0,
    }
  );

  const Export = ({ onExport }) => 
  <button className="bg-[#EFCA04] hover:bg-[#debb02] ml-auto text-black font-bold py-2 px-4 rounded inline-flex items-center cursor-pointer" onClick={e => onExport(e.target.value)}>
    <CgExport className="w-4 h-4 mr-2" size={40} />
    <span>Export CSV</span>
  </button>;
  // const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(repositoriesData)} />, []);

  function convertArrayOfObjectsToCSV(array) {
    let result;
  
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(repositoriesData[0]);
  
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
  
        result += item[key];
        
        ctr++;
      });
      result += lineDelimiter;
    });
  
    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;
  
    const filename = 'export.csv';
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  async function handleStatusChange(id, novoStatus) {
    try {
      await api.patch(`/user/${id}/status`, { status: novoStatus });
      console.log(handleStatusChange);
      fetchPendingUsers();
      const showToastMessage = () => {
        toast.success(`Sucesso ao alterar status do prestador `, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessage();
    } catch (error) {
      const showToastMessageError = () => {
        toast.error(`Não foi possivel completar a operação`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessageError();
      console.log(error);
    }
  }

  // const columns = [
  //   { field: "nome", headerName: "Nome", width: 250 },
  //   {
  //     field: "tipo",
  //     headerName: "Tipo",
  //     flex: 0.5,
  //     valueGetter: (params) => {
  //       if (params.row.tipo === "medico") {
  //         return "Médico";
  //       } else if (params.row.tipo === "clinica") {
  //         return "Clinica";
  //       } else {
  //         return "Vendedor";
  //       }
  //     },
  //   },
  //   {
  //     field: "aceitar",
  //     headerName: "Aceitar",
  //     flex: 0.5,
  //     renderCell: (params) => (
  //       <GridActionsCellItem
  //         icon={<AceitarButton>Aceitar</AceitarButton>}
  //         label="Aceitar"
  //         onClick={() => handleStatusChange(params.id, "Active")}
  //       />
  //     ),
  //   },
  //   {
  //     field: "recusar",
  //     headerName: "Recusar",
  //     flex: 0.5,
  //     renderCell: (params) => (
  //       <GridActionsCellItem
  //         icon={<RecusarButton>Recusar</RecusarButton>}
  //         label="Recusar"
  //         onClick={() => handleStatusChange(params.id, "Suspended")}
  //       />
  //     ),
  //   },
  //   {
  //     field: "visualizar",
  //     type: "actions",
  //     align: "center",
  //     getActions: (params) => [
  //       <GridActionsCellItem
  //         icon={<BsEyeFill size={20} color="#7A99E3" />}
  //         onClick={() => {
  //           history.push(`/pendentes/${params.id}`);
  //         }}
  //         label="Visualizar"
  //       />,
  //     ],
  //   },
  // ];

  const colunasTabela = [
    {
      name: 'Nome',
      selector: row => row.nome,
    },
    {
      name: 'CRM',
      selector: row => row.numero_de_registro_profissional,
    },
    {
      name: 'Aceitar',
      selector: row => <AceitarButton onClick={() => handleStatusChange(row.id, "Active")}>Aceitar</AceitarButton>,
    },
    {
      name: 'Recusar',
      selector: row => <RecusarButton onClick={() => handleStatusChange(row.id, "Suspended")}>Recusar</RecusarButton>,
    },
    {
      name: '',
      maxWidth: '40px',
      selector: row => 
      <BsEyeFill
      className="cursor-pointer" 
      onClick={() => {
        history.push(`/pendentes/${row.id}`);
      }} size={20} color="#7A99E3" 
      />,
    },
  ];

  const fetchPendingUsers = async (proximaPagina = false, ultimaPagina = false) => {
    setLoading(true);
    setErrorRepositories(null);

    try {
      console.log("caçando pendentes");
      let modifier = 0;
      if (proximaPagina) {
        modifier = 1;
      }
      if (ultimaPagina) {
        modifier = -1;
      }
      const pendentes = await api.get(`/medicos/listar_pendentes?page=${infoPagina.page + modifier}&per_page=20`);
      console.log("irra", pendentes);
      const mergedData = [...pendentes.data.medicos];
      console.log(pendentes.data);
      console.log("rapaz", mergedData);
      setRepositoriesData(mergedData);
      setInfoPagina(
        {
          has_next: pendentes.data.has_next,
          has_previous: pendentes.data.has_previous,
          per_page: pendentes.data.per_page,
          page: pendentes.data.page,
          total: pendentes.data.total,
        }
      );
    } catch (error) {
      setErrorRepositories("Erro ao buscar usuários pendentes");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const proximaPagina = async (proxima) => {
    console.log("foi de next");
    if (proxima) {
      if (infoPagina.has_next) {
        await fetchPendingUsers(true, false);
      }
    }
    else {
      if (infoPagina.has_previous) {
        await fetchPendingUsers(false, true);
      }
    }
  };

  useEffect(() => {
    fetchPendingUsers();
  }, []);

  function refresh() {
    window.location.reload();
  }

  if (errorRepositories) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Pendentes</PageTitle>
          <ErrorMsg>{errorRepositories}</ErrorMsg>
          <ErrorBtn onClick={(event) => refresh(event)}>
            Tentar novamente
          </ErrorBtn>
        </PageContainer>
      </Page>
    );
  }

  if (loading) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Pendentes</PageTitle>
          <ReactLoading
            type="spin"
            color="#B2EBF2"
            height={"30px"}
            width={"30px"}
          />
        </PageContainer>
      </Page>
    );
  }

  // Return antigo para a tabela antiga
  // return (
  //   <Page>
  //     <SidebarMenu />
  //     <PageContainerDiv>
  //       <Background>
  //         <RightDiv>
        
  //           <PageTitle>Pendentes</PageTitle>
  //           <Tabela rows={repositoriesData} columns={columns} />
  //         </RightDiv>
  //       </Background>
  //     </PageContainerDiv>
  //   </Page>
  // );

  return (
    <Page>
      <SidebarMenu />
      <PageContainerDiv>
        <Background>
          <RightDiv>
        
            <PageTitle>Pendentes</PageTitle>
            {/* <Tabela rows={repositoriesData} columns={columns} /> */}
            <div className="flex flex-col w-full space-y-2">
              <Export onExport={() => downloadCSV(repositoriesData)} />
              <div className="w-full max-h-96 overflow-x-auto overflow-y-visible">
                <DataTable
                  columns={colunasTabela}
                  data={repositoriesData}
                  responsive
                  customStyles={{
                    headCells: {
                      style: {
                        backgroundColor: "#EFCA04",
                        fontSize: "16px",
                      },
                    },
                  }}
                />
              </div>

              <div className="flex flex-row w-full justify-between items-center">
                <BsArrowLeft 
                className={infoPagina.has_previous ? "cursor-pointer" : "cursor-not-allowed"}
                onClick={() => proximaPagina(false)} 
                size={50} 
                color={infoPagina.has_previous ? "#EFCA04" : "#ebe0a7"} />
                <p className=" text-[#EFCA04]">
                  {infoPagina.page + "/" + Math.ceil(infoPagina.total/infoPagina.per_page)}
                </p>
                <BsArrowRight 
                className={infoPagina.has_next ? "cursor-pointer" : "cursor-not-allowed"}
                onClick={() => proximaPagina(true)} 
                size={50} 
                color={infoPagina.has_next ? "#EFCA04" : "#ebe0a7"} />
              </div>
            </div>
            
          </RightDiv>
        </Background>
      </PageContainerDiv>
    </Page>
  );
}
