import appstore from "../../Assets/botaoappstore.png"
import playstore from "../../Assets/botaogoogleplay.png"

const ContainerPrestadorLP = () => {
  return (
    <div className="my-32 bg-roxo w-[90%] md:w-[80%] md:h-[400px] h-[450px] rounded-3xl">
        <div className="md:w-[60%] w-[90%] h-full md:ml-32 md:pr-48 flex flex-col justify-center items-center md:items-start px-5 md:px-0 gap-4">
        <h1 className="text-verdeClaro font-bold text-base md:text-5xl">Bem vindo!</h1>
        <p className="text-amarelo">
        Somos a NOVI Saúde! Oferecemos acesso fácil a médicos, exames e procedimentos sem burocracia, com preços acessíveis por créditos pré-pagos. Baixe nosso app para iOS ou Android, cadastre-se e confira nossos profissionais credenciados. Inicie uma conversa para marcar sua consulta de forma simples. Sem mensalidade, compre créditos conforme suas possibilidades. No consultório, você autoriza a consulta. Diga adeus aos planos caros que não atendem suas necessidades. Venha para a NOVI, um novo jeito de cuidar da sua saúde!
        </p>
        <div className="flex gap-2">
        
        <button id="scaleMedicoLP" className="cursor-pointer bg-transparent"><img className="w-32 rounded-md bg-verdeClaro" src={appstore}/></button>
        <button id="scaleMedicoLP" className="cursor-pointer bg-transparent"><img className="w-32 rounded-md bg-verdeClaro" src={playstore}/></button>
        </div>
        </div>
        

    </div>
  );
};

export default ContainerPrestadorLP;
