import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SidebarMenu from "../../../Components/SidebarMenu";
import api from "../../../Services/api";
import { Page } from "../../SignedOut/Cadastro/styles";
import { PageTitle } from "../CadastrarMedico/styles";
import { PacientInfo, PacientRow, InfoBlock, InfoTitle, Info } from "./styles";
import ReactLoading from "react-loading";
import Affiliates from "../../../Components/Afilliate";
import { RecusarButton } from "../Pendentes/styled";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import PageContainer from "../../../Components/PageContainer";
import { Background, PageContainerDiv, RightDiv } from "../Perfil/styles";

export default function PacienteUnico() {
  const { id } = useParams();
  const [paciente, setPaciente] = useState([]);
  const [loading, setLoading] = useState(true);
  const [requestDone, setRequestDone] = useState(false);
  const [repositoriesData, setRepositoriesData] = useState([]);
  const [pacienteUsers, setPacienteUsers] = useState([]);
  const history = useHistory();

  async function handleStatusChange(id, novoStatus) {
    try {
      await api.patch(`/user/${id}/status`, { status: novoStatus });
      console.log(handleStatusChange);
      fetchPacienteUsers();
      const showToastMessage = () => {
        toast.success(`Paciente Bloqueado`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessage();
      history.push("/pacientes");
    } catch (error) {
      const showToastMessageError = () => {
        toast.error(`Não foi possível bloquear o paciente`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessageError();
      console.log(error);
    }
  }

  const fetchPacienteUsers = async () => {
    setLoading(true);
    try {
      const paciente = await api.get("/users?filter=status;eq;Active");
      const mergedData = [...paciente.data];
      console.log(paciente.data);
      setRepositoriesData(mergedData);
      setPacienteUsers({ paciente });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchPacienteUsers();
  // }, []);

  useEffect(() => {
    const fetchRepositoriesData = async () => {
      setLoading(true);

      try {
        const response = await api.get("/user/" + id);
        setPaciente(response.data);
        console.log("paciente", response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setRequestDone(true);
      }
    };
    fetchRepositoriesData();
  }, [id]);

  if (loading || !requestDone) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainerDiv>
          <SidebarMenu />
          <Background>
            <RightDiv>
              <PageTitle>Paciente</PageTitle>
              <ReactLoading
                type="spin"
                color="#B2EBF2"
                height={"30px"}
                width={"30px"}
              />
            </RightDiv>
          </Background>
        </PageContainerDiv>
      </Page>
    );
  }

  return (
    <Page>
      <SidebarMenu />

      <PageContainerDiv>
        <SidebarMenu />
        <Background>
          <RightDiv>
            <PageTitle>Paciente</PageTitle>
            <PacientInfo>
              <PacientRow>
                <InfoBlock>
                  <InfoTitle>Nome:</InfoTitle>
                  <Info>{paciente?.nome}</Info>
                </InfoBlock>
                <InfoBlock>
                  <InfoTitle>CPF:</InfoTitle>
                  <Info>{paciente?.paciente?.cpf}</Info>
                </InfoBlock>
              </PacientRow>
              <PacientRow>
                <InfoBlock>
                  <InfoTitle>Email:</InfoTitle>
                  <Info>{paciente?.email}</Info>
                </InfoBlock>
                <InfoBlock>
                  <InfoTitle>Telefone:</InfoTitle>
                  <Info>{paciente?.paciente?.telefone}</Info>
                </InfoBlock>
              </PacientRow>
              <PacientRow>
                <InfoBlock>
                  <InfoTitle>Afiliados:</InfoTitle>
                  <Info>
                    {paciente?.paciente?.afiliados?.length === 0 || !requestDone
                      ? "Não há pacientes afiliados."
                      : paciente?.paciente?.afiliados?.map((item, index) => (
                          <div key={index}>
                            <Affiliates afiliado={item} />
                          </div>
                        ))}
                  </Info>
                </InfoBlock>
                <InfoBlock>
                  <InfoTitle>Créditos:</InfoTitle>
                  <Info>{paciente.paciente.creditos} crédito(s)</Info>
                </InfoBlock>
              </PacientRow>
              <InfoBlock>
                <InfoTitle>Bloquear usuário:</InfoTitle>
                <RecusarButton
                  onClick={() => handleStatusChange(paciente.id, "Suspended")}
                >
                  Bloquear
                </RecusarButton>
              </InfoBlock>
            </PacientInfo>
          </RightDiv>
        </Background>
      </PageContainerDiv>
    </Page>
  );
}
