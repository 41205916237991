import styled from "styled-components";

export const ProviderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10vh;
`;

export const ProviderRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15vh;
`;

export const InfoBlock = styled.p`
  display: flex;
  flex-direction: column;
  gap: 1vh;
  width: 25vw;
`;

export const InfoTitle = styled.h3`
  color: ${(props) => props.theme.colors.darkBlue};
`;

export const Info = styled.p`
  font-size: large;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PageTitle = styled.p`
  font-weight: bold;
  color: ${(props) => props.theme.colors.black};
  border-bottom: 2px solid ${(props) => props.theme.colors.darkBlue};
  padding-top: 3%;
  font-size: 32px;
`;

export const RecusarButton = styled.div`
  background-color: red;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
`;
