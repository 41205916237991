import Exemplo1 from "../../Assets/ex1.png";
import Exemplo2 from "../../Assets/ex2.png";
import Exemplo3 from "../../Assets/ex3.png";
import Exemplo4 from "../../Assets/ex4.png";
import { register } from "swiper/element/bundle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../CarrosselLP/CarrosselLP.css";
register();

const CarrosselLP = () => {
	const data = [
		{ id: "1", image: Exemplo1 },
		{ id: "2", image: Exemplo2 },
		{ id: "3", image: Exemplo3 },
		{ id: "4", image: Exemplo4 },
	];
	return (
		<div className="bg-roxoClaro">
			<Swiper
				slidesPerView={1}
				pagination={{ clickable: true }}
				autoplay={{ delay: 10000 }}
				className="bg-roxoClaro md:w-[85%] flex justify-center"
			>
				{data.map((item) => (
					<SwiperSlide key={item.id}>
						<img
							src={item.image}
							alt="Slider"
							id="scaleMedicoLP"
							className="bg-roxoClaro w-full cursor-pointer h-[550px] object-cover md:h-[570px] md:rounded-xl  transition-transform duration-300 hover:scale-95"
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default CarrosselLP;
