import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Menu = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 60vw;
	gap: 3vh;
	padding-bottom: 4vh;
`;

export const MenuItem = styled.li`
	color: #663780;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 55px;
	min-width: 108px;

	background-color: white;
	a {
		text-decoration: none;
		font-size: 20px;
		padding: 0 20px;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		border: 2px solid #663780;
		border-radius: 5px;
		color: #663780;
		background-color: white;
		&:hover {
			cursor: pointer;
			background-color: #663780;
			color: #f3f3f3;
		}
	}
`;

export const MenuLink = styled(NavLink)`
	background: none;
	background-color: none;
`;
