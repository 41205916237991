import styled from 'styled-components';
import grafismo from '../../../Assets/grafismo.png';

export const Page = styled.div`
    display: flex;
    flex-direction: row;
`;

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: url('${grafismo}'), url('${grafismo}');
    background-position: top -55px left -55px, bottom -55px right -55px;
background-repeat: no-repeat, no-repeat;
background-size: 55%
    
    
    
`;

export const RegisterContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5vh;
    padding: 2%;
    border-radius: 10px;
    background-color: ${props => props.theme.colors.roxo};
    color:${props => props.theme.colors.brancoID};
`;

export const PageTitle = styled.p`
    font-size: xx-large;
    font-family: 'Cairo', sans-serif; /* ou 'Cairo', sans-serif; */
    font-weight:bold;
    color:#EFCA04;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3vh;
    align-items: center;
`;

export const RegisterInput = styled.input`
    padding: 1%;
    width: 50vw;
    height: 25px;
    border-radius: 50px;
    background-color: ${props => props.theme.colors.lightGrey};
    font-size: larger;
    color: black;
    padding-left: 20px;
`;

export const RegisterButton = styled.button`
    height: 40px;
    width: 20vw;
    cursor: pointer;
    background-color: ${props => props.theme.colors.verdeClaro};
    border-radius: 15px;
    font-size: larger;
    font-weight: bold;
    color: ${props => props.theme.colors.brancoID};
    display: flex;
    justify-content: center;
    align-items: center;
`;