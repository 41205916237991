import styled from "styled-components";

export const AceitarButton = styled.button`
  background-color: green;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  text-align: center;

  font-size: 14px;
`;
export const RecusarButton = styled.div`
  background-color: #e93737;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
`;
