import styled from "styled-components";

export const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5vh;
    padding: 3%;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.roxo};
    color: ${(props) => props.theme.colors.brancoID};
`;

export const LoginInput = styled.input`
    padding: 1%;
    width: 35vw;
    height: 35px;
    border-radius: 50px;
    background-color: ${(props) => props.theme.colors.lightGrey};
    font-size: larger;
    color: black;
    padding-left: 20px;
`;

export const LoginButton = styled.button`
    height: 40px;
    width: 15vw;
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.verdeClaro};
    border-radius: 15px;
    font-size: larger;
    font-weight: bold;
    color: ${(props) => props.theme.colors.brancoID};
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Lexend', sans-serif;
`;

export const ErrorText = styled.h4`
    color: ${(props) => props.theme.colors.red};
    text-align: center;
`;

export const RedirectContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
`;

export const RedirectButton = styled.button`
    width: max-content;
    font-size: large;
    background: none;
    cursor: pointer;
    color: ${(props) => props.theme.colors.verdeClaro};
    font-family: 'Lexend', sans-serif;
`;


export const LoginRow = styled.div`
width: 100%;
display: flex;
flex-direction: column;
margin: 10px;
margin-top: 2vh;
`
export const ButtonRow = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin-top: 5vh;

`