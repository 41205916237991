import styled from "styled-components";
import grafismo from "../../../Assets/grafismo.png";

export const PageContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 82vw;
  height: 100%;
  min-height: 90vh;
  align-items: center;
  padding-left: max(18vw, 200px);
`;

export const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 80%;
  gap: 7vh;
  padding: 2%;
  background-color: ${(props) => props.theme.colors.roxo};
  border-radius: 15px;
`;

export const Background = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-image: url("${grafismo}"), url("${grafismo}");
  background-position: top -150px left -150px, bottom -150px right -150px;
  background-repeat: no-repeat, no-repeat;
  background-size: 55%;
`;

export const Redefini = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const InfoBlock = styled.p`
  display: flex;
  flex-direction: column;
`;

export const InfoTitle = styled.h3`
  color: ${(props) => props.theme.colors.verdeClaro};
  font-family: 'Cairo', sans;
`;

export const Info = styled.p`
  font-size: large;
  color: ${(props) => props.theme.colors.brancoID};
  font-family: 'Lexend', sans;
`;

export const Content = styled.div`
  display: flex;
  gap: 100px;
  margin-top: 5%;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: 100vh;
  gap: 7vh;
  align-items: center;
  padding-left: 20vw;
`;

export const PageTitle = styled.h1`
  font-weight: bold;
  color: ${(props) => props.theme.colors.amarelo};
  border-bottom: 2px solid ${(props) => props.theme.colors.pretoID};
  padding-top: 3%;
  font-family: 'Cairo', 'sans';
`;

export const Status = styled.div`
  display: flex;
  padding: 5px 10px;
  background-color: red;
  color: white;
  font-size: large;

  border-radius: 5px;
  align-items: center;
  justify-content: center;
`;
