import React from "react";
import { Field, ErrorMessage } from "formik";
import { ErrorStyled, FieldStyle, Label, RequiredLabel } from "../style";

function Input({ name, type = "", label, required, ...props }) {
	return (
		<>
			<Label>
				{label || name}
				{required && <RequiredLabel>*</RequiredLabel>}
			</Label>
			<Field as={FieldStyle} name={name} type={type} {...props}></Field>
			<ErrorMessage name={name} component={ErrorStyled}></ErrorMessage>
		</>
	);
}

export default Input;
