import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SidebarMenu from "../../../Components/SidebarMenu";
import api from "../../../Services/api";
import { Page } from "../../SignedOut/Cadastro/styles";
import ReactLoading from "react-loading";
import PageContainer from "../../../Components/PageContainer";

import {
  PacientInfo,
  InfoBlock,
  InfoTitle,
  Info,
  Content,
  Status,
  PageTitle,
} from "./styles";
import { useAuth } from "../../../Hooks/useAuth";
import StatusDisplay from "../../../Components/Status";

export default function ConsultaUnica() {
  const { id } = useParams();
  const [consulta, setConsulta] = useState([]);
  const [medico, setMedico] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchRepositoriesData = async () => {
      setLoading(true);

      try {
        const response = await api.get(`/medico/${user.id}/consultas?id=${id}`);
        setConsulta(response?.data[0]);
        console.log("consulta", response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchRepositoriesData();
  }, [id]);

  // useEffect(() => {
  //   const fetchMedicoData = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await api.get(`/user/${consulta?.to_user_id}`);
  //       setMedico(response?.data);
  //       console.log("medico", response.data);
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchMedicoData();
  // }, [consulta]);

  const dataDoServidor = consulta?.created_at;
  const dataBr = new Date(dataDoServidor).toLocaleDateString("pt-BR");

  if (loading) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Histórico de Consultas</PageTitle>
          <ReactLoading
            type="spin"
            color="#B2EBF2"
            height={"30px"}
            width={"30px"}
          />
        </PageContainer>
      </Page>
    );
  }

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Consulta</PageTitle>
        <Content>
          <PacientInfo>
            {consulta.afiliado_nome && (
              <>
                <InfoBlock>
                  <InfoTitle>Nome do Dependente:</InfoTitle>
                  <Info>{consulta?.afiliado_nome} </Info>
                </InfoBlock>
                <InfoBlock>
                  <InfoTitle>CPF:</InfoTitle>
                  <Info>{consulta?.afiliado_cpf}</Info>
                </InfoBlock>
              </>
            )}

            <InfoBlock>
              <InfoTitle>Nome do Titular:</InfoTitle>
              <Info>{consulta?.from_user_name}</Info>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>CPF:</InfoTitle>
              <Info>{consulta?.paciente_cpf}</Info>
            </InfoBlock>

            <InfoBlock>
              <InfoTitle>Email:</InfoTitle>
              <Info>{consulta?.email_paciente}</Info>
            </InfoBlock>

            <InfoBlock>
              <InfoTitle>Telefone:</InfoTitle>
              <Info>{consulta?.telefone_paciente}</Info>
            </InfoBlock>

            <InfoBlock>
              <InfoTitle>Data da consulta:</InfoTitle>
              <Info>{dataBr}</Info>
            </InfoBlock>
          </PacientInfo>

          <PacientInfo>
            {/* <InfoBlock>
              <InfoTitle>Especialidade:</InfoTitle>
              <Info>
                {medico?.medico?.especialidades.map((item) => item.nome + " ")}
              </Info>
            </InfoBlock> */}
            <InfoBlock>
              <InfoTitle>Valor da Consulta:</InfoTitle>
              <Info>{consulta.amount} crédito(s)</Info>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Status:</InfoTitle>

              <StatusDisplay status={consulta.status}>
                {consulta.status}
              </StatusDisplay>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Código do Pagamento:</InfoTitle>

              <Info>{consulta.token}</Info>
            </InfoBlock>
          </PacientInfo>
        </Content>
      </PageContainer>
    </Page>
  );
}
