import { Helmet } from "react-helmet";
import FooterLP from "../../../Components/FooterLP";
import HeaderLP from "../../../Components/HeaderLP";
import MainLP from "../../../Components/MainLP";

const LandingPage = () => {
    return(
        <div className="flex flex-col max-w-screen h-screen bg-roxoClaro justify-between">
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-0JBVHTP24R"></script>
                <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-0JBVHTP24R');
                `}
                </script>
            </Helmet>

            <HeaderLP/>
            <MainLP/>
            <FooterLP/>
        </div>
    )
}

export default LandingPage;