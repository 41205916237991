import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SidebarMenu from "../../../Components/SidebarMenu";
import api from "../../../Services/api";
import { Page } from "../../SignedOut/Cadastro/styles";
import { PageTitle} from "../Creditos/styles";
import { InfoBlock, InfoTitle, Info } from "../PacienteUnico/styles";
import { ProviderInfo, ProviderRow } from "./styles";
import ReactLoading from "react-loading";
import { RecusarButton } from "../Pendentes/styled";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import PageContainer from "../../../Components/PageContainer";
import InfosMedico from "../../../Components/InfosMedico";
import InfosClinica from "../../../Components/InfosClinica";
import InfosVendedor from "../../../Components/InfosVendedor";
import BlockButton from "../../../Components/BlockButton";

export default function PrestadorUnico() {
  const { id } = useParams();
  const [prestador, setPrestador] = useState([]);
  const [loading, setLoading] = useState(true);
  const [requestDone, setRequestDone] = useState(false);

  const history = useHistory();

  async function handleStatusChange(id, novoStatus) {
    try {
      await api.patch(`/user/${id}/status`, { status: novoStatus });
      console.log(handleStatusChange);
      const showToastMessage = () => {
        toast.success(`Prestador Bloqueado`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessage();
      history.push("/prestadores");
    } catch (error) {
      const showToastMessageError = () => {
        toast.error(`Não foi possível bloquear o prestador`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessageError();
      console.log(error);
    }
  }

  useEffect(() => {
    const fetchRepositoriesData = async () => {
      setLoading(true);

      try {
        const response = await api.get("/user/" + id);
        setPrestador(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setRequestDone(true);
      }
    };
    fetchRepositoriesData();
  }, [id]);

  if (loading || !requestDone) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Prestador</PageTitle>
          <ReactLoading
            type="spin"
            color="#067187"
            height={"30px"}
            width={"30px"}
          />
        </PageContainer>
      </Page>
    );
  }

  if (prestador?.tipo === "medico") {
    return (
      <InfosMedico
        prestador={prestador}
        buttons={
          <BlockButton
            onClick={() => handleStatusChange(prestador.id, "Suspended")}
          />
        }
      />
    );
  } else if (prestador?.tipo === "vendedor") {
    return (
      <InfosVendedor
        prestador={prestador}
        buttons={
          <BlockButton
            onClick={() => handleStatusChange(prestador.id, "Suspended")}
          />
        }
      />
    );
    // } else if (prestador?.tipo === "clinica") {
    //   return (
    //     <InfosClinica
    //       prestador={prestador}
    //       buttons={
    //         <BlockButton
    //           onClick={() => handleStatusChange(prestador.id, "Suspended")}
    //         />
    //       }
    //     />
    //   );
  } else {
    <PageContainer>
      <Info>Erro ao carregar prestador</Info>
    </PageContainer>;
  }
}
