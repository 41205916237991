import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import theme from "../../../Theme";

export const Page = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 7vh;
  align-items: center;
  margin-left: 20vw;
  padding-bottom: 5%;
`;

export const PageTitle = styled.h1`
  font-weight: bold;
  color: ${(props) => props.theme.colors.verdeEscuro};
  border-bottom: 2px solid ${(props) => props.theme.colors.pretoID};
  padding-top: 3%;
`;

export const ErrorMsg = styled.p`
  padding-top: 10%;
  font-size: large;
  color: ${(props) => props.theme.colors.red};
`;

export const ErrorBtn = styled.button`
  width: 15vw;
  height: 8vh;
  font-size: medium;
  cursor: pointer;
  align-items: center;
  border-radius: 20px;
  color: white;
  background-color: ${(props) => props.theme.colors.darkBlue};
`;

export const DataList = styled.div`
  width: 65vw;
`;

export const MyDataGrid = styled(DataGrid)`
  width: 80%;
  height: 100%;
  border-radius: 20px;
`;

export const Status = styled.div`
  display: flex;
  padding: 5px 10px;
  background-color: ${(props) => props.status};
  color: white;
  font-size: large;
  border-radius: 5px;
`;
export const TabsContainer = styled.div`
  display: flex;
  gap: 10px;
  
`;

export const Tab = styled.div`
  display: flex;
  flex: 1;
  text-align: center;
  background-color: ${({ active }) =>
    active ? theme.colors.roxo : "white"};
  color: white;
  border-radius: 5px;
  cursor: pointer;
  color: ${({ active }) => (active ? "white" : theme.colors.roxo)};
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  transition: 0.3s;
  &:hover {
    background-color: ${({ active }) =>
      active ? theme.colors.roxo : theme.colors.roxo};
    color: white;
    border: ${({ active }) =>
      active ? "none" : `1px solid ${theme.colors.roxo}`};
  }
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  border: 1px solid ${({ active }) => (active ? "none" : theme.colors.roxo)};
`;

export const Text = styled.p`
  font-size: 1.2rem;
  margin-top: 1%;
  margin-bottom: 1%;
`;


export const FilterContainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
padding: 10px;
background-color: #000;
color: #fff;
`;

export const DateFilter = styled.div`
flex: 1;
display: flex;
flex-direction: column;
margin: 10px;
`;

export const Label = styled.label`
  color: ${theme.colors.roxo}; /* Use a cor azul do Tab */
  margin-bottom: 5px;
`;

export const Input = styled.input`
  padding-left: 5px;
  font-size: 18px; /* Defina o tamanho desejado, por exemplo, 16px */
`;

export const Button = styled.button`
  background-color: ${theme.colors.verdeEscuro};
  color: #fff;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 20px; /* Defina o tamanho desejado, por exemplo, 16px */
  margin-left: 15px;
  font-family: 'Lexend', 'sans';
`;

export const TabelaContainer = styled.div`
  background-color: ${theme.colors.darkBlue};
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 20px; /* Defina o tamanho desejado, por exemplo, 18px */
`;

