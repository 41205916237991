import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../Services/api';

import Sidebar from "../../../Components/Sidebar";
import {Page, PageContainer, InputContainer, PageTitle} from '../Cadastro/styles';
import {PasswordResetContainer, SendButton, PasswordInfo} from '../EsqueciMinhaSenha/styles';
import { PasswordInput } from './styles';
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorText } from '../../SignedIn/CadastrarMedico/styles';

export default function PasswordReset(props){
    const history = useHistory();
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [loading, setLoading] = useState('');
    const [invalidFields, setInvalidFields] = useState(false);
    const {token} = useParams();

    function handleSubmit() {
        if (password !== passwordConfirm) {
            const showToastMessage = () => {
                toast.error(`As senhas inseridas não coincidem!`, {
                    position: toast.POSITION.TOP_CENTER
                });
              }
            showToastMessage()
          return;
        }
        if (password.length < 8) {
            const showToastMessage = () => {
                toast.error(`Sua senha precisa conter no mínimo 8 caracteres!`, {
                    position: toast.POSITION.TOP_CENTER
                });
              }
                showToastMessage()
          return;
        }
        sendData();
    };

    const sendData = async () => {
        setLoading(true);
        try {
            const config = {
                headers: { Authorization: `Bearer ${props.location.state}` }
            };
            const data = {
                senha: password,
            };

            const response = await api.post(`/redefinir-senha`, data, config);

            console.log(response);
            const showToastMessage = () => {
                toast.success(`Sua senha foi redefinida com sucesso!`, {
                    position: toast.POSITION.TOP_CENTER
                });
              }
                showToastMessage()
            history.push("/");
        } catch (error) {
            console.log(error);
            const showToastMessage = () => {
                toast.error(`Erro ao redefinir sua senha`, {
                    position: toast.POSITION.TOP_CENTER
                });
              }
                showToastMessage()
        }
        setLoading(false);
    };

    useEffect(() => {
        if (!password || !passwordConfirm || loading) {
            setInvalidFields(true);
        } else {
            setInvalidFields(false);
        }
    }, [password, passwordConfirm, loading]);
    

    return(
        <Page>
            <Sidebar/>
            <PageContainer>
                <PasswordResetContainer>
                    <PageTitle>Esqueci minha senha</PageTitle>
                    <PasswordInfo>
                        Insira sua nova senha abaixo:
                    </PasswordInfo>
                    <InputContainer>
                        <PasswordInput
                            placeholder='Senha'
                            value={password}
                            type="password"
                            onChange={(event) => setPassword(event.target.value)}
                        />
                        <PasswordInput
                            placeholder='Confirme sua senha'
                            value={passwordConfirm}
                            type="password"
                            onChange={(event) => setPasswordConfirm(event.target.value)}
                        />
                        {invalidFields && <ErrorText>Senhas não conferem</ErrorText>}
                        <SendButton onClick={event => handleSubmit(event)} disabled={invalidFields}>
                            {loading ?
                                <ReactLoading type="spin" color="#B2EBF2" height={"30px"} width={"30px"}/> :
                                "Enviar"
                            }
                        </SendButton>
                    </InputContainer>
                </PasswordResetContainer>
            </PageContainer>
        </Page>
    ) 
}