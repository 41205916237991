import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GridToolbar, ptBR } from "@mui/x-data-grid";
import { ptBR as coreptBR } from "@mui/material/locale";
import { MyDataGrid } from "./styles";

const Tabela = ({ rows, columns, props }) => {
  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    ptBR, // x-data-grid translations
    coreptBR // core translations
  );

  return (
    <ThemeProvider theme={theme}>
      <MyDataGrid
        {...props}
        rows={rows}
        columns={columns}
        rowHeight={50}
        // autoHeight
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        disableColumnSelector
        disableColumnSorting
        disableColumnMenu
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#EFCA04",
            fontWeight: "17",
            color: "black",
            fontSize: "1.1rem",
            fontFamily: "'Cairo', sans-serif;"
          },
          "& .MuiDataGrid-row": {
            color:"#663780",
            backgroundColor:"white",
            fontFamily: "'Lexend', sans-serif",
            "&:hover": {
              backgroundColor: "#067187",
              color: "white",
            },
          },
      
        }}
      />
    </ThemeProvider>
  );
};

export default Tabela;
