import React from "react";
import { NavLink } from "react-router-dom";
import {
  PageContainer,
  PageTitle,
} from "../../Pages/SignedIn/CadastrarMedico/styles";

import { Page } from "../../Pages/SignedOut/Cadastro/styles";
import SidebarMenu from "../SidebarMenu";
import { RegisterButton, RegisterContainer, RegisterText } from "./styles";
import {
  Background,
  PageContainerDiv,
  RightDiv,
} from "../../Pages/SignedIn/Perfil/styles";

export default function EscolherUsuario({
  pageName,
  commandText,
  information,
}) {
  return (
    <Page>
      <SidebarMenu />
      <PageContainerDiv>
        <SidebarMenu />
        <Background>
          <RightDiv height={"50%"} width={"60%"} gap={"2vh"}>
            <PageTitle>{pageName}</PageTitle>
            <RegisterContainer>
              <RegisterText>{commandText}</RegisterText>
              {information.map((item, index) => {
                return (
                  <NavLink key={index} to={item.path}>
                    <RegisterButton>{item.category}</RegisterButton>
                  </NavLink>
                );
              })}
            </RegisterContainer>
          </RightDiv>
        </Background>
      </PageContainerDiv>
    </Page>
  );
}
