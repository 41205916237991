import styled from "styled-components";
import theme from "../../Theme";

export const ProviderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10vh;
`;

export const ProviderRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15vh;
`;

export const InfoBlock = styled.p`
  display: flex;
  flex-direction: column;
  gap: 1vh;
  width: 25vw;
`;

export const InfoTitle = styled.h3`
  color: ${(props) => props.theme.colors.roxo};
`;

export const Info = styled.p`
  font-size: large;
  color: ${(props) => props.theme.colors.verdeEscuro};
`;

export const Page = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PageTitle = styled.p`
  font-weight: bold;
  color: ${(props) => props.theme.colors.amarelo};
  border-bottom: 2px solid ${(props) => props.theme.colors.pretoID};
  padding-top: 3%;
  font-size: 32px;
`;

export const RecusarButton = styled.div`
  background-color: red;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
`;

export const ImageContainer = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const InputPrestador = styled.input`
 padding-left: 5px;
  font-size: 18px;
`
export const ButtonPrestador = styled.button`
background-color: ${theme.colors.verdeClaro};
  color: #fff;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Lexend', 'sans-serif';
`
