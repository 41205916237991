import React, { useState } from "react";
import api from "../../Services/api";

import { AffiliateLine } from "./styles";

export default function Affiliates({ afiliado }) {
  console.log(afiliado);
  if (!afiliado) {
    return <></>;
  }
  return (
    <AffiliateLine>
      {afiliado.nome}{" "}
      {afiliado.cpf_afiliado && " CPF: " + afiliado.cpf_afiliado};
    </AffiliateLine>
  );
}
