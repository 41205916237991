import styled from "styled-components";

export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vh;
  padding: 4%;
  width: 32.5vw;
  /* border: 2px solid ${(props) => props.theme.colors.darkBlue}; */
  border-radius: 20px;
  /* box-shadow: 0 0 1em ${(props) => props.theme.colors.darkBlue}; */
  
`;

export const RegisterText = styled.p`
  /* color: ${(props) => props.theme.colors.mediumBlue}; */
  text-align: center;
  font-weight: bold;
  font-size: larger;
  padding-bottom: 3%;
  color:${(props) => props.theme.colors.brancoID};
`;

export const RegisterButton = styled.button`
  width: 15vw;
  height: 5vh;
  border-radius: 13px;
  cursor: pointer;
  color: #fff;
  //background-color: ${(props) => props.theme.colors.greenBlue};
  background-color:${(props) => props.theme.colors.verdeClaro};
  font-size: larger;
  font-family: 'Lexend', sans-serif;
`;
