import LogoBranca from "../LogoBranca";
import email from "../../Assets/emailLP.png";
import telefone from "../../Assets/telefoneLP.png";

const FooterLP = () => {
    return(
        <div className=" md:px-10 px-2 bg-roxo text-brancoID items-center flex justify-between">
            <LogoBranca/>
            <div className="flex gap-2 text-sm md:text-base">
            <div className="flex justify-center items-center"><img className="w-5 mr-1" alt="email" src={email}/><p>novi.saude@gmail.com</p></div>
            <div className="flex justify-center items-center"><img className="w-5 mr-1" alt="email" src={telefone}/><p>22 99977-1187</p></div>
            </div>
        </div>
    )
}

export default FooterLP;
