import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

function IdentityPhotoUploader({ onFileUpload, text }) {
	const [uploadedFile, setUploadedFile] = useState(null);

	const onDrop = useCallback(
		(acceptedFiles) => {
			if (acceptedFiles.length > 0) {
				const selectedFile = acceptedFiles[0];
				setUploadedFile(selectedFile);
				onFileUpload(selectedFile);
			}
		},
		[onFileUpload]
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: "image/*",
		multiple: false,
	});

	return (
		<div>
			<div {...getRootProps()} style={dropzoneStyles}>
				<input {...getInputProps()} />
				<p>{text}</p>
				{uploadedFile && (
					<div>
						{/*  <p>Arquivo carregado com sucesso:</p>
            <p>Nome do arquivo: {uploadedFile.name}</p>
            <p>Tipo do arquivo: {uploadedFile.type}</p>
            <p>Tamanho do arquivo: {uploadedFile.size} bytes</p> */}
						<img
							src={URL.createObjectURL(uploadedFile)}
							alt="Visualização da identidade funcional"
							style={imageStyles}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

const dropzoneStyles = {
	border: "2px dashed #cccccc",
	borderRadius: "4px",
	padding: "20px",
	textAlign: "center",
	cursor: "pointer",
	maxWidth: "40rem",
};

const imageStyles = {
	maxWidth: "100%",
	maxHeight: "200px",
	marginTop: "10px",
};

export default IdentityPhotoUploader;
