import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SidebarMenu from "../../../Components/SidebarMenu";
import api from "../../../Services/api";
import { Page } from "../../SignedOut/Cadastro/styles";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";
import {
  PacientInfo,
  InfoBlock,
  InfoTitle,
  Info,
  Content,
  PageTitle,
  Title,
} from "./styles";
import StatusDisplay from "../../../Components/Status";
import MainButton from "../../../Components/MainButton";
import PageContainer from "../../../Components/PageContainer";

export default function PagamentoUnico() {
  const { id } = useParams();
  const [consulta, setConsulta] = useState(null);
  const [paciente, setPaciente] = useState(null);
  const [medico, setMedico] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchRepositoriesData = async () => {
      try {
        const response = await api.get(`/medico/consultas?id=${id}`);
        setConsulta(response?.data[0]);
        console.log("consulta", response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchRepositoriesData();
  }, [id]);

  useEffect(() => {
    const fetchPacienteData = async () => {
      try {
        const response = await api.get(`/user/${consulta?.from_user_id}`);
        setPaciente(response?.data);
        console.log("paciente", response.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchMedicoData = async () => {
      try {
        const response = await api.get(`/user/${consulta?.to_user_id}`);
        setMedico(response?.data);
        console.log("medico", response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPacienteData();
    fetchMedicoData();
  }, [consulta]);

  const dataDoServidor = consulta?.created_at;
  const dataBr = new Date(dataDoServidor).toLocaleDateString("pt-BR");

  if (!(consulta && paciente && medico)) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Consulta</PageTitle>
          <ReactLoading
            type="spin"
            color="#B2EBF2"
            height={"30px"}
            width={"30px"}
          />
        </PageContainer>
      </Page>
    );
  }

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Consulta</PageTitle>
        <Content>
          {paciente && (
            <PacientInfo>
              <Title> Informações do Paciente </Title>

              {consulta.afiliado_nome && (
                <>
                  <InfoBlock>
                    <InfoTitle>Nome do Dependente:</InfoTitle>
                    <Info>{consulta?.afiliado_nome} </Info>
                  </InfoBlock>
                  <InfoBlock>
                    <InfoTitle>CPF:</InfoTitle>
                    <Info>{consulta?.afiliado_cpf}</Info>
                  </InfoBlock>
                </>
              )}

              <InfoBlock>
                <InfoTitle>Nome do Titular:</InfoTitle>
                <Info>{paciente?.nome}</Info>
              </InfoBlock>
              <InfoBlock>
                <InfoTitle>CPF:</InfoTitle>
                <Info>{paciente?.paciente?.cpf}</Info>
              </InfoBlock>

              <InfoBlock>
                <InfoTitle>Email:</InfoTitle>
                <Info>{paciente?.email}</Info>
              </InfoBlock>

              <InfoBlock>
                <InfoTitle>Telefone:</InfoTitle>
                <Info>{paciente?.paciente?.telefone}</Info>
              </InfoBlock>
            </PacientInfo>
          )}
          {medico && (
            <PacientInfo>
              <Title> Informações do Médico </Title>
              <InfoBlock>
                <InfoTitle>Nome do Médico:</InfoTitle>
                <Info> {consulta.to_user_name} </Info>
              </InfoBlock>
              <InfoBlock>
                <InfoTitle>CPF</InfoTitle>
                <Info>{medico?.medico?.cpf}</Info>
              </InfoBlock>

              <InfoBlock>
                <InfoTitle>Email:</InfoTitle>
                <Info>{medico?.email}</Info>
              </InfoBlock>

              <InfoBlock>
                <InfoTitle>Telefone:</InfoTitle>
                <Info>{medico?.medico?.telefone}</Info>
              </InfoBlock>
            </PacientInfo>
          )}
          <PacientInfo>
            <Title> Informações da Consulta </Title>
            <InfoBlock>
              <InfoTitle>Especialidade:</InfoTitle>
              <Info>
                {" "}
                {medico?.medico?.especialidades.map(
                  (item) => item.nome + " "
                )}{" "}
              </Info>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Valor da Consulta:</InfoTitle>
              <Info>{consulta?.amount} crédito(s)</Info>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Data da Consulta:</InfoTitle>
              <Info>{dataBr}</Info>
            </InfoBlock>

            <InfoBlock>
              <InfoTitle>Status:</InfoTitle>

              <StatusDisplay status={consulta?.status}>
                {consulta?.status}
              </StatusDisplay>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Código do Pagamento:</InfoTitle>

              <Info>{consulta?.token}</Info>
            </InfoBlock>
          </PacientInfo>
        </Content>
        {/* {consulta?.status === "Successful" && ( */}
        <MainButton
          onClick={() =>
            history.push(`/pagamentos-administrador/${id}/pagar`, {
              medico: medico,
              consulta: consulta,
            })
          }
        >
          Fazer Pagamento
        </MainButton>
        {/* )} */}
      </PageContainer>
    </Page>
  );
}
