import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SidebarMenu from "../../../Components/SidebarMenu";
import api from "../../../Services/api";
import { Page } from "../../SignedOut/Cadastro/styles";
import ReactLoading from "react-loading";
import { useHistory, useLocation } from "react-router-dom";
import {
  PacientInfo,
  InfoBlock,
  InfoTitle,
  Info,
  Content,
  Status,
  PageTitle,
  Title,
} from "./styles";
import { useAuth } from "../../../Hooks/useAuth";
import StatusDisplay from "../../../Components/Status";
import MainButton from "../../../Components/MainButton";
import { toast } from "react-toastify";
import PageContainer from "../../../Components/PageContainer";

export default function Pagar(props) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [creditos, setCreditos] = useState([]);
  const { medico, consulta } = props.location.state;

  console.log("medico", medico);
  console.log("consulta", consulta);

  // const fetchRepositoriesData = async () => {
  //   try {
  //     const response = await api.get(`/medico/consultas?id=${id}`);
  //     setConsulta(response?.data[0]);
  //     console.log("consulta", response.data[0], "OIIII");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const fetchMedicoData = async () => {
  //   try {
  //     const response = await api.get(`/user/${consulta?.to_user_id}`);
  //     setMedico(response?.data);
  //     console.log("medioco", response.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchCreditos = async () => {
    try {
      const response = await api.get(`/creditos`);
      setCreditos(response?.data.valor_credito);
      console.log("creditos", response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          // fetchRepositoriesData(),
          // fetchPacienteData(),
          // fetchMedicoData(),
          fetchCreditos(),
        ]);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id,consulta]);

  const handleStatusChange = async () => {
    try {
      const response = await api.post(`/transferencia/admin/${id}`, {});
      console.log("response", response);
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Pagar</PageTitle>
          <ReactLoading
            type="spin"
            color="#B2EBF2"
            height={"30px"}
            width={"30px"}
          />
        </PageContainer>
      </Page>
    );
  }

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Pagar</PageTitle>
        <Content>
          {medico && (
            <>
              <PacientInfo>
                <Title> Chave Pix do Prestador </Title>
                <InfoBlock>
                  <InfoTitle>Chave Pix:</InfoTitle>
                  <Info>{medico?.dados_bancarios?.chave_pix}</Info>
                </InfoBlock>
              </PacientInfo>
              <PacientInfo>
                <Title> Endereço do Prestador </Title>
                <InfoBlock>
                  <InfoTitle>Endereço:</InfoTitle>
                  <Info>{medico?.dados_bancarios?.address}</Info>
                </InfoBlock>
                <InfoBlock>
                  <InfoTitle>Número:</InfoTitle>
                  <Info>{medico?.dados_bancarios?.number}</Info>
                </InfoBlock>
                <InfoBlock>
                  <InfoTitle>Bairro:</InfoTitle>
                  <Info>{medico?.dados_bancarios?.neighbourhood}</Info>
                </InfoBlock>
                <InfoBlock>
                  <InfoTitle>Cidade:</InfoTitle>
                  <Info>{medico?.dados_bancarios?.city}</Info>
                </InfoBlock>
                <InfoBlock>
                  <InfoTitle>Estado:</InfoTitle>
                  <Info>{medico?.dados_bancarios?.state}</Info>
                </InfoBlock>
                <InfoBlock>
                  <InfoTitle>CEP:</InfoTitle>
                  <Info>{medico?.dados_bancarios?.cep}</Info>
                </InfoBlock>
              </PacientInfo>
            </>
          )}

          <PacientInfo>
            <Title> Pagamento </Title>
            <InfoBlock>
              <InfoTitle>Status:</InfoTitle>

              <StatusDisplay status={consulta?.status}>
                {consulta?.status}
              </StatusDisplay>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Valor da Consulta:</InfoTitle>
              <Info>{consulta?.amount} crédito(s)</Info>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Valor da Consulta em Reais:</InfoTitle>
              <Info>R$ {consulta?.amount * creditos} </Info>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Valor de Repasse:</InfoTitle>
              <Info>
                R${" "}
                {(
                  medico?.medico?.valor_de_repasse *
                  creditos *
                  consulta?.amount
                ).toFixed(2)}
              </Info>
            </InfoBlock>

            <InfoBlock>
              <InfoTitle>Valor a ser Pago:</InfoTitle>
              <Info>
                R${" "}
                {(
                  consulta?.amount * creditos -
                  medico?.medico?.valor_de_repasse * creditos * consulta?.amount
                ).toFixed(2)}
              </Info>
            </InfoBlock>

            <MainButton
              bgcolor={
                consulta?.status === "Credito Transferido" ? undefined : "#ff6961"
              }
              onClick={() => {
                if (consulta?.status === "Credito Transferido") {
                  toast.success("Pagamento feito!")
                  handleStatusChange();
                } else if (consulta?.status === "Pix Realizado") {
                  toast.error("O pagamento já foi realizado ao médico");
                } else {
                  toast.error(
                    "O pagamento ainda não foi realizado pelo paciente"
                  );
                }
              }}
            >
              Marcar como Pago
            </MainButton>
          </PacientInfo>
        </Content>
      </PageContainer>
    </Page>
  );
}
