import styled from "styled-components";

export const InputSenha = styled.input`
display: flex;
border: 1px solid #b4b4b4;
border-radius: 8px;
height: 4vh;
padding-left: 0.5vw;
`

export const Boxcoluna = styled.div`
display: flex;
flex-direction: column;
margin-top: 10vh;
`

export const Boxpad = styled.div`
display: flex;
padding-bottom: ${(props)=> props.pad}vh;
padding-top: 1vh;
flex-direction: column;
`

export const Boxborda = styled.div`
display: flex;
flex-direction: column;
border: 5px solid  ${props => props.theme.colors.roxo};
box-shadow:  ${props => props.theme.colors.roxo};

width:60vw;
height: 60vh;
align-items: center;
justify-content: center;
border-radius: 10px;
`

export const ConfirmBtn = styled.button`
    cursor: pointer;
    height: max-content;
    width: max-content;
    font-size: medium;
    padding: 1.5%;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.verdeEscuro};
    color: ${(props) => props.theme.colors.brancoID};
    margin-left: 15vw;
`;


export const SenhaInput = styled.input`
    padding: 1%;
    width: 40vw;
    height: 25px;
    border-radius: 50px;
    background-color: ${props => props.theme.colors.brancoID};
    font-size: larger;
    color: black;
    padding-left: 20px;
`;

export const Boxcentro = styled.div`
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;

`
