import styled from "styled-components";
import InputMask from "react-input-mask";

export const FieldStyle = styled(InputMask)`
  //padding: 1%;
  width: 36vw;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  background-color: #fafafa;
  font-size: larger;
  color: black;
  padding-left: 20px;
  font-family: 'Lexend', sans-serif;
`;

export const RequiredLabel = styled.span`
  color: red;
`;
export const Label = styled.label`
  text-transform: capitalize;
`;

export const ErrorStyled = styled.span`
  color: red;
  font-size: 14px;
`;
