import styled from "styled-components";

export const Status = styled.div`
  display: flex;
  padding: 5px 10px;
  background-color: ${(props) => props.status};
  color: white;
  font-size: large;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
`;
