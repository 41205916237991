import axios from "axios";

const api = axios.create({
  baseURL: "https://inovaclin-f1cdd023f5f9.herokuapp.com/",
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("@SaudeDoPovo:token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
