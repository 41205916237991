
import MobileLogo from "../../Assets/LogoBrancaMobile.png"
import Logo from "../../Assets/LogoBranca.png"

const LogoBranca = () => {
    return(
        <div>
            <img id="scaleLP" className="transition-transform duration-300 hover:scale-90 cursor-pointer w-[200px] h-auto hidden md:flex" src={Logo} alt="Logo Novi"></img>
            <img className=" cursor-pointer w-[200px] h-[80px] w-auto md:hidden" src={MobileLogo} alt="Logo Novi"></img>
        </div>
    )
}

export default LogoBranca;