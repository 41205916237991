const br = require("apexcharts/dist/locales/pt-br.json");

const dataGenero = [53, 47];

export const optionsGenero = {
	chart: {
		locales: [br],
		defaultLocale: "br",
	},
	colors: ["#663780", "#49B69B"],
	options: {
		legend: {
			offsetY: 20,
			offsetX: 10,
			markers: { fillColors: ["#663780", "#49B69B"] },
			labels: {
				colors: ["#4D4D4D"],
				useSeriesColors: false,
			},
		},
		chart: {
			zoom: {
				enabled: false,
			},
			fontFamily: "'Lexend'",
			foreColor: "#535353",
		},
		dataLabels: {
			enabled: false,
		},

		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200,
					},
					legend: {
						position: "bottom",
					},
				},
			},
		],
		fill: { colors: ["#663780", "#49B69B"] },
		stroke: {
			show: false,
			width: 2,
			dashArray: 0,
			colors: ["#663780", "#49B69B"],
		},
		title: {
			text: "Gênero Titulares",
			align: "center",
			offsetX: 0,

			style: {
				color: "#929292",
				fontStyle: "normal",
				fontWeight: 600,
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		labels: [`Feminino ${dataGenero[0]}%`, `Masculino ${dataGenero[1]}%`],
	},
};

const dataTitulares = [20, 22, 18, 17, 26];

export const optionsTitulares = {
	series: dataTitulares,
	chart: {
		locales: [br],
		defaultLocale: "br",
	},
	colors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"],
	options: {
		legend: {
			offsetY: 20,
			markers: { fillColors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"] },
			labels: {
				colors: ["#4D4D4D"],
				useSeriesColors: false,
			},
		},
		chart: {
			zoom: {
				enabled: false,
			},
			fontFamily: "'Lexend'",
			foreColor: "#535353",
		},
		dataLabels: {
			enabled: false,
		},

		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200,
					},
					legend: {
						position: "bottom",
					},
				},
			},
		],
		fill: { colors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"] },
		stroke: {
			show: false,
			width: 2,
			dashArray: 0,
			colors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"],
		},
		title: {
			text: "Titulares",
			align: "left",
			offsetX: 0,

			style: {
				color: "#929292",
				fontStyle: "normal",
				fontWeight: 600,
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		labels: [
			`Até 5 anos %`,
			`5 a 18 anos %`,
			`19 a 35 anos %`,
			`35 a 60 anos %`,
			`Maior de 60 anos %`,
		],
	},
};

const dataDependentes = [20, 22, 18, 17, 26];

export const optionsDependentes = {
	series: dataDependentes,
	chart: {
		locales: [br],
		defaultLocale: "br",
	},
	colors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"],
	options: {
		legend: {
			offsetY: 20,
			markers: { fillColors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"] },
			labels: {
				colors: ["#4D4D4D"],
				useSeriesColors: false,
			},
		},
		chart: {
			zoom: {
				enabled: false,
			},
			fontFamily: "'Lexend'",
			foreColor: "#535353",
		},
		dataLabels: {
			enabled: false,
		},

		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200,
					},
					legend: {
						position: "bottom",
					},
				},
			},
		],
		fill: { colors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"] },
		stroke: {
			show: false,
			width: 2,
			dashArray: 0,
			colors: ["#663780", "#49B69B", "#067187", "#EFCA04", "#929292"],
		},
		title: {
			text: "Dependentes",
			align: "left",
			offsetX: 0,

			style: {
				color: "#929292",
				fontStyle: "normal",
				fontWeight: 600,
				fontSize: "16px",
				lineHeight: "20px",
			},
		},
		labels: [
			`Até 5 anos ${dataDependentes[0]}%`,
			`5 a 18 anos ${dataDependentes[1]}%`,
			`19 a 35 anos ${dataDependentes[1]}%`,
			`35 a 60 anos ${dataDependentes[1]}%`,
			`Maior de 60 anos ${dataDependentes[1]}%`,
		],
	},
};
