import React, { useEffect, useState } from "react";
import api from "../../../Services/api";
import { GridActionsCellItem } from "@mui/x-data-grid";

import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { PageTitle } from "../CadastrarMedico/styles";
import { ErrorMsg, ErrorBtn, MyDataGrid } from "./styles";
import { BsEyeFill } from "react-icons/bs";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";
import PageContainer from "../../../Components/PageContainer";
import Tabela from "../../../Components/Tabela";
import { Background, PageContainerDiv, RightDiv } from "../Perfil/styles";

export default function Pacientes() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorRepositories, setErrorRepositories] = useState(null);
  const [repositoriesData, setRepositoriesData] = useState([]);

  const columns = [
    { field: "nome", headerName: "Nome", width: 250 },
    { field: "telefone", headerName: "Telefone", flex: 1 },
    { field: "cpf", headerName: "CPF", flex: 1 },
    {
      field: "afiliados",
      headerName: "Afiliados",
      align: "center",
      flex: 0.5,
    },
    {
      field: "visualizar",
      type: "actions",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<BsEyeFill size={20} color="#6AB3BC" />}
          onClick={() => history.push(`/pacientes/${params.id}`)}
          label="Visualizar"
        />,
      ],
    },
  ];

  useEffect(() => {
    const fetchRepositoriesData = async () => {
      setLoading(true);
      setErrorRepositories(null);

      try {
        const response = await api.get("/pacientes/listar", {
          params: { status: "Active" },
        });
        const repositories = response.data.pacientes
          .filter((item) => item.status === "Active")
          .map((item) => ({
            id: item.id,
            nome: item.nome,
            email: item.email,
            cpf: item.paciente.cpf,
            afiliados: item.paciente.afiliados.length,
            telefone: item.paciente.telefone,
            creditos: item.paciente.creditos,
          }));
        setRepositoriesData(repositories);
      } catch (error) {
        setErrorRepositories("Ocorreu um erro ao carregar a página");
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchRepositoriesData();
  }, []);

  function refresh() {
    window.location.reload();
  }

  if (errorRepositories) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainerDiv>
          <SidebarMenu />
          <Background>
            <RightDiv>
              <PageTitle>Usuários</PageTitle>
              <ErrorMsg>{errorRepositories}</ErrorMsg>
              <ErrorBtn onClick={(event) => refresh(event)}>
                Tentar novamente
              </ErrorBtn>
            </RightDiv>
          </Background>
        </PageContainerDiv>
      </Page>
    );
  }

  if (loading) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainerDiv>
          <SidebarMenu />
          <Background>
            <RightDiv>
              <PageTitle>Usuários</PageTitle>
              <ReactLoading
                type="spin"
                color="#B2EBF2"
                height={"30px"}
                width={"30px"}
              />
            </RightDiv>
          </Background>
        </PageContainerDiv>
      </Page>
    );
  }

  return (
    <Page>
      <SidebarMenu />
      <PageContainerDiv>
        <SidebarMenu />
        <Background>
          <RightDiv>
            <PageTitle>Usuários</PageTitle>
            <Tabela rows={repositoriesData} columns={columns} />
          </RightDiv>
        </Background>
      </PageContainerDiv>
    </Page>
  );
}
