import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { InputContainer } from "../CadastrarMedico/styles";
import { PageTitle} from "./styles";
import PageContainer from "../../../Components/PageContainer";

import {
  PageOptions,
  Option,
  OptionTitle,
  OptionBtn,
  CreditsLine,
  CreditsText,
} from "./styles";
import api from "../../../Services/api";

export default function Creditos() {
  const [allCredits, setAllCredits] = useState(0);
  const [creditsVisible, setCreditsVisible] = useState(false);
  const [paymentVisible, setPaymentVisible] = useState(false);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getCredits = async () => {
      setLoading(true)
      try {
        const response = await api.get("/creditos/total");
        const { total_creditos_plataforma } = response.data;
        setAllCredits(total_creditos_plataforma);
        console.log(total_creditos_plataforma);
        console.log(response.data)
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false)
      }
    };
    getCredits();
  }, []);

  // useEffect(() => {
  //     const fetchRepositoriesData = async () => {
  //         setLoading(true);
  //         setErrorRepositories(null);

  //         try {
  //             const response = await api.get("/pacientes/listar");
  //             const repositories = response.data.pacientes.map((item) => ({
  //                 id: item.id,
  //                 nome: item.nome,
  //                 email: item.email,
  //                 cpf: item.paciente.cpf,
  //                 afiliados: item.paciente.afiliados.length,
  //                 telefone: item.paciente.telefone,
  //                 creditos: item.paciente.creditos,
  //             }));
  //             setRepositoriesData(repositories);
  //         } catch (error) {
  //             setErrorRepositories("Ocorreu um erro ao carregar a página");
  //             console.log(error);
  //         } finally {
  //             setLoading(false);
  //         }
  //     };
  //     fetchRepositoriesData();
  // }, []);

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Configurações</PageTitle>
        <PageOptions>
          {/* <Option>
                        <OptionTitle>Transferência de créditos</OptionTitle>
                        <NavLink to="/creditos/transferencia">
                            <OptionBtn>
                                Transferir créditos entre os usuários
                            </OptionBtn>
                        </NavLink>
                    </Option> */}
          <Option>
            <OptionTitle>Valor de um crédito</OptionTitle>
            <NavLink to="/valor-crédito">
              <OptionBtn>Definir o valor de um crédito</OptionBtn>
            </NavLink>
          </Option>
          <Option>
            <OptionTitle>Quantidade de créditos</OptionTitle>

            {creditsVisible ? (
              <CreditsLine>
                <CreditsText>
                  Atualmente o sistema possui {loading ? "(carregando...)" : allCredits} créditos
                </CreditsText>
                <OptionBtn onClick={() => setCreditsVisible(!creditsVisible)}>
                  Ocultar
                </OptionBtn>
              </CreditsLine>
            ) : (
              <OptionBtn onClick={() => setCreditsVisible(!creditsVisible)}>
                Checar a quantidade de todos os créditos existentes
              </OptionBtn>
            )}
          </Option>
          <Option>
            <OptionTitle>Repasse do pagamento</OptionTitle>
            <NavLink to="/repasse-pagamento">
              <OptionBtn>Definir o valor do repasse</OptionBtn>
            </NavLink>
          </Option>
        </PageOptions>
      </PageContainer>
    </Page>
  );
}
