import React, { useEffect, useState } from "react";
import api from "../../../Services/api";
import { useHistory } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BsEyeFill } from "react-icons/bs";
import ReactLoading from "react-loading";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Page, PageTitle, ErrorMsg, ErrorBtn } from "./styles";

import { useAuth } from "../../../Hooks/useAuth";
import Tabela from "../../../Components/Tabela";
import StatusDisplay from "../../../Components/Status";

import { Tab, TabsContainer, Text, Label, Input, Button } from "./styles";

import PageContainer from "../../../Components/PageContainer";

export default function PagamentosAdministrador() {
  const history = useHistory();
  const [filtro, setFiltro] = useState({ minDate: null, maxDate: null });
  const [loading, setLoading] = useState(true);
  const [errorRepositories, setErrorRepositories] = useState(null);
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [activeTab, setActiveTab] = useState("PIX realizado");
  const [minDateTabActive, setMinDateTabActive] = useState(false);
  const [maxDateTabActive, setMaxDateTabActive] = useState(false);

  function filterString() {
    let string = "";
    if (filtro.minDate || filtro.maxDate) {
      string += "?";
    }
    if (filtro.minDate) {
      string += "data_min=" + filtro.minDate;
    }
    if (filtro.maxDate) {
      if (filtro.minDate) {
        string += "&";
      }
      string += "data_max=" + filtro.maxDate;
    }
    return string;
  }

  useEffect(() => {
    fetchAppointmentsData();
  }, []);

  const fetchAppointmentsData = async () => {
    setLoading(true);
    setErrorRepositories(null);
    const string = filterString();

    try {
      console.log("Fetching data with filtro:", filtro);
      const response = await api.get(`/medico/consultas${string}`);
      console.log(string, "oi");
      console.log("response.data", response.data);
      setAppointmentsData(response.data);
    } catch (error) {
      setErrorRepositories("Ocorreu um erro ao carregar a página");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { field: "medico_user_name", headerName: "Prestador", flex: 1 },
    {
      field: "combined_name",
      headerName: "Paciente/Dependente",
      valueGetter: (params) => {
        const fromUserName = params.row.from_user_name;
        const afiliadoNome = params.row.afiliado_nome;

        if (fromUserName && afiliadoNome) {
          return `${fromUserName} / ${afiliadoNome}`;
        } else if (fromUserName) {
          return fromUserName;
        } else if (afiliadoNome) {
          return afiliadoNome;
        } else {
          return "Nome não disponível";
        }
      },
      flex: 1.5,
    },
    {
      field: "created_at",
      headerName: "Data",
      flex: 1.2,
      type: "dateTime",
      valueGetter: ({ value }) => {
        if (value) {
            const date = new Date(value);
            date.setHours(date.getHours() - 3); // Diminui 3 horas da data
            return date;
        }
        return null;
    },
    },
    { field: "amount", headerName: "Valor", flex: 0.5 },
    {
      field: "status",
      headerName: "Status",
      flex: 1.5,
      type: "singleSelect",
      valueOptions: [
        "Créditos transferidos",
        "Pix realizado",
        "Pendente",
        "Créditos insuficientes",
        "Falha",
      ],
      renderCell: (params) => (
        <StatusDisplay status={params.row.status}>
          {params.row.status}
        </StatusDisplay>
      ),
    },
    {
      field: "visualizar",
      type: "actions",
      align: "center",
      flex: 0.5,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<BsEyeFill size={20} color="#7A99E3" />}
          onClick={() => history.push(`/pagamentos-administrador/${params.id}`)}
          label="Visualizar"
        />,
      ],
    },
  ];

  function refresh() {
    window.location.reload();
  }

  const uniqueStatus = [
    ...new Set(appointmentsData.map((item) => item.status)),
  ];

  const ordemDosStatus = [
    "Créditos transferidos",
    "Pix realizado",
    "Pendente",
    "Créditos insuficientes",
    "Falha",
  ];

  const tabsToRender = [
    ...uniqueStatus
      .sort((a, b) => ordemDosStatus.indexOf(a) - ordemDosStatus.indexOf(b))
      .map((status) => (
        <Tab
          key={status}
          active={activeTab === status}
          onClick={() => {
            setActiveTab(status);
            setMinDateTabActive(false);
            setMaxDateTabActive(false);
          }}
        >
          <Text>{status}</Text>
        </Tab>
      )),
  ];

  if (errorRepositories) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Histórico de Consultas</PageTitle>
          <ErrorMsg>{errorRepositories}</ErrorMsg>
          <ErrorBtn onClick={refresh}>Tentar novamente</ErrorBtn>
        </PageContainer>
      </Page>
    );
  }

  if (loading) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Consulta</PageTitle>
          <ReactLoading
            type="spin"
            color="#B2EBF2"
            height={"30px"}
            width={"30px"}
          />
        </PageContainer>
      </Page>
    );
  }

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Pagamentos</PageTitle>
        <TabsContainer>{tabsToRender}</TabsContainer>
        <div>
          <Label>Data mínima:</Label>
          <Input
            type="date"
            value={filtro?.minDate}
            onChange={(e) => {
              console.log("Min date selected:", e.target.value);
              setFiltro({ ...filtro, minDate: e.target.value });
            }}
          />
          <Label>Data máxima:</Label>
          <Input
            type="date"
            value={filtro?.maxDate}
            onChange={(e) => {
              console.log("Max date selected:", e.target.value);
              setFiltro({ ...filtro, maxDate: e.target.value });
            }}
          />
          <Button onClick={() => fetchAppointmentsData()}>Aplicar</Button>
          <Button onClick={refresh}>Recarregar</Button>
        </div>
        {!minDateTabActive && !maxDateTabActive && (
          <Tabela
            rows={
              activeTab === "Outros"
                ? appointmentsData.filter(
                    (item) => !uniqueStatus.includes(item.status)
                  )
                : appointmentsData.filter((item) => item.status === activeTab)
            }
            columns={columns}
          />
        )}
      </PageContainer>
    </Page>
  );
}
