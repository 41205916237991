import React, { useEffect, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { PageTitle } from "../CadastrarMedico/styles";
import { Option, OptionTitle, PageOptions } from "../Creditos/styles";
import {
  CreditsInput,
  TypeSelect,
  OptionRow,
  TransferButton,
  CreditsDiv,
  InputAsync,
} from "./styles";
import ReactLoading from "react-loading";
import api from "../../../Services/api";
import PageContainer from "../../../Components/PageContainer";

export default function Transferencia() {
  const [loading, setLoading] = useState(false);
  const [invalidFields, setInvalidFields] = useState(false);

  const [credits, setCredits] = useState();
  const [donatorType, setDonatorType] = useState("");
  const [donator, setDonator] = useState("");
  const [receiverType, setReceiverType] = useState("");
  const [receiver, setReceiver] = useState("");
  const [doador, setDoador] = useState("");
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [coinDonator, setCoinDonator] = useState(0);
  const [coinReciver, setCoinReciver] = useState(0);

  useEffect(() => {
    const loadOptions = async () => {
      try {
        const response = await api.get(`/${donatorType}/listar`);
        const data = await response.data.medicos;
        setOptions(data);
      } catch (error) {
        console.log(error, "Error 12");
      }
    };
    loadOptions();
  }, [donatorType]);

  useEffect(() => {
    const loadOptions2 = async () => {
      try {
        console.log(donatorType);
        const response = await api.get(`/${receiverType}/listar`);
        const data = await response.data.medicos;
        setOptions2(data);
      } catch (error) {
        console.log(error, "Error 123");
      }
    };
    loadOptions2();
  }, [receiverType]);

  useEffect(() => {
    const coinnss = options.filter((id) => {
      for (let i = 0; i < arr.length; i++) {
        if (options[i].id === doador) {
          setCoinDonator(options[i].medico.creditos);
        }
      }
    });
  }, [doador]);

  useEffect(() => {
    const coinnss = options.filter((id) => {
      for (let i = 0; i < arr.length; i++) {
        if (options2[i].id === receiver) {
          setCoinReciver(options[i].medico.creditos);
        }
      }
    });
  }, [receiver]);

  function handleSubmit() {
    if (!doador || !receiver || credits || !receiverType || !donatorType) {
      setInvalidFields(true);
    } else {
      setInvalidFields(false);
    }
  }

  const sendData = async () => {
    setCoinDonator(coinDonator - credits);
    const soma = credits;
    const soma2 = coinReciver;
    const resultado = soma - soma2;
    setCoinReciver(resultado + coinReciver);
    setCredits("");
    try {
      const data = {
        coinDonator: coinDonator,
        coinReciver: coinReciver,
      };
      const response = api.post("/transferencia", data);
    } catch (error) {
      console.log(error);
    }
  };

  const arr = options.map((user) => ({ value: user.id, label: user.nome }));
  const arr2 = options2.map((user) => ({ value: user.id, label: user.nome }));

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Transferência de créditos</PageTitle>
        <PageOptions>
          <Option>
            <OptionTitle>Quantidade de créditos</OptionTitle>
            <OptionRow gap={"20px"}>
              <CreditsInput
                value={credits}
                onChange={(event) => setCredits(event.target.value)}
              />{" "}
              créditos
            </OptionRow>
          </Option>
          <Option>
            <OptionTitle>Usuário doador</OptionTitle>
            <OptionRow gap={"100px"}>
              <TypeSelect
                value={donatorType}
                onChange={(event) => setDonatorType(event.target.value)}
              >
                <option disabled={donatorType}>
                  Selecione o tipo de usuário
                </option>
                <option value="medicos">Prestador</option>
                <option value="vendedor">Vendedor</option>
                <option value="paciente">Paciente</option>
              </TypeSelect>
              <InputAsync
                user={donator}
                setUser={setDonator}
                userType={donatorType}
                options={arr}
                onChange={(event) => setDoador(event?.value)}
                defaultOptions
                placeholder="Pesquisar usuário pelo nome"
                noOptionsMessage={() => "name not found"}
              />

              <OptionRow gap={"30px"}>
                <CreditsDiv value={credits}> {coinDonator}</CreditsDiv> créditos
              </OptionRow>
            </OptionRow>
          </Option>
          <Option>
            <OptionTitle>Usuário recebedor</OptionTitle>
            <OptionRow gap={"100px"}>
              <TypeSelect
                value={receiverType}
                onChange={(event) => setReceiverType(event.target.value)}
              >
                <option disabled={receiverType}>
                  Selecione o tipo de usuário
                </option>
                <option value="medicos">Prestador</option>
                <option value="vendedor">Vendedor</option>
                <option value="paciente">Paciente</option>
              </TypeSelect>
              <InputAsync
                user={receiver}
                setUser={setReceiver}
                userType={receiverType}
                options={arr2}
                onChange={(event) => setReceiver(event?.value)}
                defaultOptions
                placeholder="Pesquisar usuário pelo nome"
                noOptionsMessage={() => "name not found"}
              />
              <OptionRow gap={"30px"}>
                <CreditsDiv value={credits}> {coinReciver}</CreditsDiv> créditos
              </OptionRow>
            </OptionRow>
          </Option>
          <TransferButton
            onClick={(event) => handleSubmit(event.target.value)}
            disabled={invalidFields}
          >
            {loading ? (
              <ReactLoading
                type="spin"
                color="#B2EBF2"
                height={"30px"}
                width={"30px"}
              />
            ) : (
              "Transferir"
            )}
          </TransferButton>
        </PageOptions>
      </PageContainer>
    </Page>
  );
}
