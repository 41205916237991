import React, { useEffect, useState } from "react";
import api from "../../../Services/api";
import { useHistory } from "react-router-dom";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { BsEyeFill } from "react-icons/bs";
import ReactLoading from "react-loading";
import SidebarMenu from "../../../Components/SidebarMenu";
import { ptBR } from "@mui/x-data-grid";
// import { ptBR as pickersptBR } from "@mui/x-date-pickers/locales";
import { ptBR as coreptBR } from "@mui/material/locale";
import {
  Page,
  PageTitle,
  ErrorMsg,
  ErrorBtn,
  MyDataGrid,
  Status,
} from "./styles";

import { useAuth } from "../../../Hooks/useAuth";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Tabela from "../../../Components/Tabela";
import StatusDisplay from "../../../Components/Status";
import PageContainer from "../../../Components/PageContainer";

export default function Prestadores() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorRepositories, setErrorRepositories] = useState(null);
  const [appointmentsData, setAppointmentsData] = useState([]);
  const { user } = useAuth();

  const columns = [
    // { field: "to_user_name", headerName: "Prestador", flex: 1 },
    {
      field: "combined_name",
      headerName: "Paciente/Dependente",
      valueGetter: (params) => {
        const fromUserName = params.row.from_user_name;
        const afiliadoNome = params.row.afiliado_nome;

        if (fromUserName && afiliadoNome) {
          return `${fromUserName} / ${afiliadoNome}`;
        } else if (fromUserName) {
          return fromUserName;
        } else if (afiliadoNome) {
          return afiliadoNome;
        } else {
          return "Nome não disponível";
        }
      },
      flex: 1.5,
    },
    {
      field: "created_at",
      headerName: "Data",
      flex: 1.5,
      type: "dateTime",
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({value}) => {
        var newDate = new Date(value.getTime() - value.getTimezoneOffset()*60*1000);
         return newDate.toLocaleString();   
      },
    },
    { field: "amount", headerName: "Valor", flex: 0.5 },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      type: "singleSelect",
      valueOptions: ["Pending", "Successful", "Failed"],
      renderCell: (params) => (
        <StatusDisplay status={params.row.status}>
          {params.row.status}
        </StatusDisplay>
      ),
    },
    {
      field: "visualizar",
      type: "actions",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<BsEyeFill size={20} color="#7A99E3" />}
          onClick={() => history.push(`/consultas/${params.id}`)}
          label="Visualizar"
        />,
      ],
    },
  ];

  useEffect(() => {
    const fetchAppointmentsData = async () => {
      setLoading(true);
      setErrorRepositories(null);

      try {
        const response = await api.get(`/medico/${user.id}/consultas`);

        setAppointmentsData(response.data);
        console.log("response.data", response.data);
      } catch (error) {
        setErrorRepositories("Ocorreu um erro ao carregar a página");
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchAppointmentsData();
  }, []);

  function refresh() {
    window.location.reload();
  }

  if (errorRepositories) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Histórico de Consultas</PageTitle>
          <ErrorMsg>{errorRepositories}</ErrorMsg>
          <ErrorBtn onClick={(event) => refresh(event)}>
            Tentar novamente
          </ErrorBtn>
        </PageContainer>
      </Page>
    );
  }

  if (loading) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Histórico de Consultas</PageTitle>
          <ReactLoading
            type="spin"
            color="#B2EBF2"
            height={"30px"}
            width={"30px"}
          />
        </PageContainer>
      </Page>
    );
  }

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Histórico de Consultas</PageTitle>
        <Tabela rows={appointmentsData} columns={columns} />
      </PageContainer>
    </Page>
  );
}
